import React, { PropsWithChildren } from "react";
import {createPortal} from "react-dom";

interface ModalProps {

}

interface ModalState {
    el: HTMLDivElement
}

class Modal extends React.Component<PropsWithChildren<ModalProps>, ModalState>{

    el = document.createElement('div');
    modalRoot = document.getElementById('modal-root');

    componentDidMount() {
        this.el.className = "modal"

        if (this.modalRoot)
            this.modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        if (this.modalRoot)
            this.modalRoot!.removeChild(this.el);
    }

    render() {
        return createPortal(
            this.props.children,
            this.el
        );
    }
}

export default Modal