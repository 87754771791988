import { ReactNode, useEffect, useState } from 'react';
import Ripple from './Ripple';

interface RippleItem {
    key: number
}

const useRipple : (effectLength: number) => [ReactNode[], () => void] = (effectLength: number) => {

    const [rippleNodes, setRipples] = useState<ReactNode[]>([]);

    const createRipple = () => {
        setRipples((prevRipples) => [
          ...prevRipples,
          (<Ripple key={Date.now()} effectLength={effectLength}/>)
        ]);
    
        setTimeout(() => {
          setRipples((prevRipples) => prevRipples.slice(1));
        }, effectLength);
      };

    return [
        rippleNodes,
        createRipple
    ];
};

export default useRipple;