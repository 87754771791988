import styled from 'styled-components'

const Avatar = (props: AvatarProps) => {
  return (
    <AvatarStyled src={props.imageUrl} $width={props.width} $height={props.height} />
  )
}

const AvatarStyled = styled.img<AvatarStyledProps>` 
  width: ${props => props.$width};
  height: ${props => props.$height};
  border-radius: 50%;
  `

export default Avatar

export interface AvatarProps {
  width: string,
  height: string,
  imageUrl: string
}

export interface AvatarStyledProps {
  $width: string,
  $height: string,
}