import { forwardRef, useContext } from "react"
import styled from "styled-components"
import { Message, MessageSide } from "../models/Message"
import MessagesContext from "../models/MessagesContext"
import Avatar from "./Avatar"
import MessageBlock from "./MessageBlock"
import SystemMessage from "./SystemMessage"
import { ConnectionState } from "../models/ConnectionStateEnum"
import { DebugMessage } from "../models/DebugMessage"
import ChatStateContext from "../models/ChatStateContext"
import { InterviewStage, InterviewStatus } from "../apiClient"
import StarRating from "./StarRating"


interface MessageWrapperProps {
  imageUrl: string;
  connectionState: ConnectionState;
  onScoreSet: (score: number) => Promise<void>;
}

const MessageWrapper = forwardRef<HTMLDivElement, MessageWrapperProps>((props, ref) => {

  const appState = useContext(ChatStateContext);
  const consumer = useContext(MessagesContext);

  const groups: MessageGroup[] = (() => {
    let groups: MessageGroup[] = [];
    let groupIndex = 0;
    for (let i = 0; i < consumer.messages.length; i++) {
      const message = consumer.messages[i];
      const prevMessage = groups[groupIndex]?.messages?.at(-1);
      if (prevMessage?.side !== message.side) {
        groupIndex++;
        groups[groupIndex] = {
          messages: [message],
          side: message.side
        };
      }
      else {
        groups[groupIndex].messages = [...groups[groupIndex].messages, message];
      }
    }

    //Ставим в последнюю группу typing, чтобы точки были внутри группы сообщений и не дублировать аватар
    if (consumer.isModeratorTyping) {
      const lastGroup = groups.at(-1);
      if (lastGroup === undefined || lastGroup.side !== MessageSide.Bot)
        groups.push({ messages: [], side: MessageSide.Bot, typing: true });
      else if (lastGroup?.side === MessageSide.Bot)
        lastGroup.typing = true;
    }
    return groups;
  })()

  const renderBotGroup = (group: MessageGroup, index: number) => (
    <MessageGroupWrapperStyled key={index}>
      <Avatar imageUrl={props.imageUrl} width="40px" height="40px" />
      <MessagesInGroupStyled>
        {group.messages.map((m, gIndex) => {
          let debugMessages: DebugMessage[] = appState.showDebugMessages && m.id ? consumer.getDebugMessages(m.id) : [];
          return (<MessageBlock key={gIndex} message={m} last={group.messages.length - 1 === gIndex && !group.typing} debugMessages={debugMessages} />)
        })}
        {group.typing && <MessageBlock key={9999} isTypingMessage last={true} debugMessages={[]} />}
      </MessagesInGroupStyled>
    </MessageGroupWrapperStyled>
  )

  const renderUserGroup = (group: MessageGroup, index: number) => {
    let messageBlocks = group.messages.map((m, gIndex) => {
      let debugMessages: DebugMessage[] = appState.showDebugMessages && m.id ? consumer.getDebugMessages(m.id) : [];
      return (<MessageBlock key={gIndex} message={m} last={group.messages.length - 1 === gIndex} debugMessages={debugMessages} />)
    });
    return <MessagesInGroupStyled>{messageBlocks}</MessagesInGroupStyled>
  }

  const connectionProblemMessage = appState.connectionState === ConnectionState.Disconnected ? appState.disconnectedText : appState.reconnectionText;

  return (
    <MessageWrapperStyled
      ref={ref}
      className='message-wrapper'
      enableWhiteGradientMask={appState.interviewStatus === InterviewStatus.Open}
    >
      {groups.map((group, outIndex) => {
        if (group.side === MessageSide.Bot)
          return renderBotGroup(group, outIndex);
        else
          return renderUserGroup(group, outIndex);
      })}
      {/* TODO может быть переделать потом на сообщение с Side=System, но пока необходимости в нескольких нет */}
      {props.connectionState !== ConnectionState.Connected
        && <SystemMessage message={connectionProblemMessage} />}
      {/* !isModeratorTyping чтобы оценка не вылезла вперёд прощального сообщения*/}
      {appState.interviewStatus !== InterviewStatus.Open && appState.interviewStage !== InterviewStage.NotStarted
        && !appState.isTestInterview && !consumer.isModeratorTyping && appState.connectionState === ConnectionState.Connected &&
        <MessageGroupWrapperStyled>
          <StarRating
            questionText={appState.conversationNaturalnessScoreRequestText}
            onSubmit={props.onScoreSet}
            isScoreSet={appState.score != null}
            style={{ marginLeft: "50px", marginRight: "25px" }} />
        </MessageGroupWrapperStyled>}
    </MessageWrapperStyled>)
})

interface MessageGroup {
  messages: Message[],
  side: MessageSide,
  typing?: boolean
}

const MessageGroupWrapperStyled = styled.div`
display: flex;
align-items: flex-end;

@media (max-width: 800px) {
  column-gap: 0.5rem;
}
`

const MessagesInGroupStyled = styled.div`
display: flex;
flex-direction: column;
gap: 0.25rem;
`

const MessageWrapperStyled = styled.div<{ enableWhiteGradientMask: boolean }>`

  --scrollbar-width: 8px;
  --mask-height:  ${p => p.enableWhiteGradientMask ? '2rem' : '0rem'};
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    black 0%,
    black calc(100% - var(--mask-height)),
    transparent);

  -webkit-mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  -webkit-mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  -webkit-mask-position: 0 0, 100% 0;
  -webkit-mask-repeat: no-repeat, no-repeat;

  display: flex;
  flex-grow: 1;
  width: 100%;
  row-gap: 16px;
  padding: 1rem;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  
  scrollbar-width: thin;
  scrollbar-color: #249de4 #f5f5f5;

  scroll-behavior: smooth;

  @media (max-width: 800px) {
    gap: 12px;
    margin-bottom:0px;
  }
  
  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    background-color: #FFFFFF;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--scrollbar-width);
    background-color: #E5E5E5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: var(--scrollbar-width);
    -moz-border-radius: var(--scrollbar-width);
    -ms-border-radius: var(--scrollbar-width);
    -o-border-radius: var(--scrollbar-width);
  }
  `

export default MessageWrapper