import styled from "styled-components"
import { DebugMessagePosition } from "../apiClient"
import { DebugMessage } from "../models/DebugMessage"
import { Message, MessageSide } from "../models/Message"
import MessageBubble from "./MessageBubble"
import SystemMessage from "./SystemMessage"

let MessageBlock = (props: MessageBlockProps) => {
  return (
    <MessageBlockContainerStyled>
      {props.debugMessages.filter(x => x.position === DebugMessagePosition.Top).map((dm, i) => (
        <SystemMessage message={dm.visibleText} tooltipMessage={dm.fulltext} key={i} />
      ))}
      <MessageBlockStyled data-message-id={props.message?.id ?? 9999} $side={props.message?.side ?? MessageSide.Bot}>
        <MessageBubble key={2} isTypingMessage={props.isTypingMessage} message={props.message} last={props.last} />
      </MessageBlockStyled>
      {props.debugMessages.filter(x => x.position === DebugMessagePosition.Bottom).map((dm, i) => (
        <SystemMessage message={dm.visibleText} tooltipMessage={dm.fulltext} key={i} />
      ))}
    </MessageBlockContainerStyled>
  )
}

const MessageBlockContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const MessageBlockStyled = styled.div<MessageBlockStyledProps>`
  display: flex;
  flex-direction: ${props => props.$side === MessageSide.Bot ? 'row' : 'row-reverse'};
  align-self: ${props => props.$side === MessageSide.Bot ? 'flex-start' : 'flex-end'};
  gap: 16px;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;

  @media (max-width: 800px) {
    gap: 12px;
    padding-left: ${props => props.$side === MessageSide.Bot ? '0px' : '1.5rem'};
    padding-right: ${props => props.$side === MessageSide.Bot ? '1.5rem' : '0px'};
  }
  `

interface MessageBlockStyledProps {
  $side: MessageSide
}

type MessageBlockProps = 
    { message?: Message, isTypingMessage: boolean, last: boolean, debugMessages: DebugMessage[] }
  | { message: Message, isTypingMessage?: boolean, last: boolean, debugMessages: DebugMessage[] }

export default MessageBlock