import React, { Ref, RefObject } from "react";

export class MessageWrapperRefContextType {
    messageWrapperRef: RefObject<HTMLDivElement> | null;
    setMessageWrapperRef: (ref: RefObject<HTMLDivElement>) => void;

    constructor() {
        this.messageWrapperRef = null;
        this.setMessageWrapperRef = (ref: RefObject<HTMLDivElement>) => {};
    }
}

const MessageWrapperRefContext = React.createContext<MessageWrapperRefContextType>(new MessageWrapperRefContextType());
export default MessageWrapperRefContext;