import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FORCED_LANGUAGE_STORAGE_KEY } from "../services/utils"
import Spinner from "./Spinner"
import VersionInfo from "./VersionInfo"
import SurveyClosedFinalScreen from "./SurveyClosedFinalScreen"
import WrongUrlScreen from "./WrongUrlScreen"
import Chat from "./Chat"
import ChatStateContext from "../models/ChatStateContext"
import NewInterviewBuilder from "./NewInterviewBuilder"

const ChatApp = (props: ChatAppProps) => {

  const queryLanguage = props.language;
  const [browserLanguage, setBrowserLanguage] = useState(navigator.language.split('-')[0]);

  const appState = useContext(ChatStateContext);

  useEffect(() => {
    if (!props.linkInvitation && !props.interviewUid) {
      // хрень какая-то
      appState.setFinalScreen({
        name: "404",
        data: {
          message: ""
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.linkInvitation, props.interviewUid, props.interviewExtId])

  const greetingLanguageSelectHandler = (code: string) => {
    localStorage.setItem(FORCED_LANGUAGE_STORAGE_KEY, code);
    window.location.reload();
  }

  if (appState.finalScreenData) {
    switch (appState.finalScreenData.name) {
      case "SurveyClosed":
        return <SurveyClosedFinalScreen
          message={appState.finalScreenData.data?.message || 'Survey closed'}
          redirectUrl={appState.finalScreenData.data?.redirectUrl}
          redirectMessage={appState.finalScreenData.data?.redirectMessage}
        />;
      case "404":
        return <WrongUrlScreen />;
    }
  }

  let content = <></>

  if (props.linkInvitation) {
    content = <NewInterviewBuilder
      linkInvitation={props.linkInvitation}
      queryLanguage={queryLanguage}
      browserLanguage={browserLanguage}
      interviewExtId={props.interviewExtId}
      querySearch={props.querySearch}
      stimulusCellUid={props.stimulusCellUid}
    />
  }
  else if (props.interviewUid) {
    content = <Chat
      interviewUid={props.interviewUid}
      browserLanguage={browserLanguage}
      greetingLanguageSelectHandler={greetingLanguageSelectHandler}
    />
  }

  return (
    <ChatStyled>
      <VersionInfo />
      {appState.isLoading && <Spinner />}
      {content}
    </ChatStyled>)
}

interface ChatAppProps {
  linkInvitation: string,
  interviewUid: string,
  interviewExtId: string,
  querySearch: string,
  stimulusCellUid: string,
  language: string
}

let ChatStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  width: 800px;

  @media (max-width: 800px) {
    width: 100%;
  }
  `

ChatApp.whyDidYouRender = true;

export default ChatApp