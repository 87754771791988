import styled, { keyframes } from "styled-components";



const rippleEffect = keyframes`
from {
    transform: scale(0);
    opacity: 0.65;
}
to {
  transform: scale(1.2);
  opacity: 0;
}
`;

const Ripple = styled.span<{effectLength: number}>`
  position: absolute;
  border-radius: 50%;
  border: 2px solid grey;
  transform: scale(0);
  animation: ${rippleEffect} ${props => `${props.effectLength}ms`} linear;
  background: white;

  min-width: 150%;
  min-height: 150%;
  aspect-ratio: 1;
`;

export default Ripple;