import { styled } from 'styled-components';

interface ChatFooterProps {
    text: string;
}

const ChatFooter = (props: ChatFooterProps) => {
    
    if (!!!props.text) 
        return null;

    return (
        <FooterStyled>
            <SpanStyled dangerouslySetInnerHTML={{ __html: props.text }} />
        </FooterStyled>
    );
};

export default ChatFooter;

const SpanStyled = styled.span`
    line-height: normal;
    padding-bottom: 0.3rem;
`

const FooterStyled = styled.footer`
    display: flex;
    flex: 1;
    align-items: center;
    opacity: 0.6;
    font-size: smaller;
    justify-content: start;
`
