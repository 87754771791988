import { styled } from "styled-components";
import { ENVIRONMENT, REGION } from "../services/utils";

const VersionInfo: React.FC = () => {

    if (!process.env.REACT_APP_COMMIT_SHA) return <></>;
    if (!REGION) return <></>;
    if (ENVIRONMENT !== 'test') return <></>;

    return <AnchorStyled
        href={`https://gitlab.corp.tiburon-research.ru/tiburon/fastuna-ai/-/commit/${process.env.REACT_APP_COMMIT_SHA}`}
        target='_blank'
        rel='noreferrer' >
        {`${process.env.REACT_APP_COMMIT_SHA || 'no SHA'} / ${REGION}`}
    </AnchorStyled >

}

const AnchorStyled = styled.a`
    font-size: 10px;
    right: 15px;
    top: 5px;
    position: fixed;
    color: gray;
    z-index: 1;
    text-decoration: none;
`;

export default VersionInfo;