import { forwardRef, useCallback, useContext, useState } from "react";
import { IoVolumeHighIcon, IoVolumeLowIcon, IoVolumeMediumIcon, IoVolumeMuteIcon } from "../../assets/Icons";
import { styled } from "styled-components";
import ControlButtonStyled from "./PlayerControlButton";
import { VideoDispatchContext, VideoStateContext } from "./VideoContextProvider";

interface PlayerAudioControlProps {
    isFullscreen: boolean;
    isVolumeControlShown: boolean;
    setVolume: (value: number) => void;
    audioVolumeContainerMouseEnterHandler: () => void;
    audioVolumeContainerMouseLeaveHandler: () => void;
}

const PlayerAudioControl = (props: PlayerAudioControlProps, videoRef: React.ForwardedRef<HTMLVideoElement>) => {
    const state = useContext(VideoStateContext);
    const dispatch = useContext(VideoDispatchContext);

    const [isMuted, setIsMuted] = useState<boolean>(false);

    const volumeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const volume = +e.target.value;
        dispatch && dispatch({ type: "setVolumeValue", payload: volume });
        props.setVolume(volume / 100);
    }

    const toggleMute = useCallback(() => {
        if (state?.storedVolume === 0 && isMuted) {
            setIsMuted(false);
            dispatch && dispatch({ type: "setVolumeValue", payload: 100 });
            props.setVolume(100 / 100);
            return;
        }

        if (isMuted) {
            props.setVolume((state?.storedVolume || 0) / 100);
            setIsMuted(false);
        }
        else {
            dispatch && dispatch({ type: "setStoredVolume", payload: state?.volumeValue || 0 });
            props.setVolume(0);
            setIsMuted(true);
        }
    }, [dispatch, isMuted, props, state?.storedVolume, state?.volumeValue])

    const volume = state?.volumeValue || 0;

    return (
        <AudioControlWrapperStyled onMouseEnter={props.audioVolumeContainerMouseEnterHandler} onMouseLeave={props.audioVolumeContainerMouseLeaveHandler}>
            <AudioVolumeControlStyled $isShown={props.isFullscreen || props.isVolumeControlShown}>
                <AudioVolumeControlInputStyled type="range" min="0" max="100" value={isMuted ? 0 : volume} onVolumeChange={volumeChangeHandler} onChange={volumeChangeHandler} />
            </AudioVolumeControlStyled>
            <AudioControlStyled onClick={toggleMute}>
                {(isMuted || volume === 0) && <IoVolumeMuteIcon />}
                {!isMuted && volume > 0 && volume <= 33 && <IoVolumeLowIcon />}
                {!isMuted && volume > 33 && volume <= 66 && <IoVolumeMediumIcon />}
                {!isMuted && volume > 66 && <IoVolumeHighIcon />}
            </AudioControlStyled>
        </AudioControlWrapperStyled>
    )
}

export default PlayerAudioControl;


const AudioControlWrapperStyled = styled.div`
display: flex;
max-width: 200px;
`

const AudioControlStyled = styled(ControlButtonStyled)`
`

interface AudioVolumeControlStyledProps {
    $isShown: boolean;
}

const AudioVolumeControlStyled = styled.div<AudioVolumeControlStyledProps>`
border-radius: 8px;
display: flex;
overflow: hidden;
width: ${props => props.$isShown ? '100%' : '0'};

* {
    background-color: white;
}
`

const AudioVolumeControlInputStyled = styled.input`
display: flex;
width: 100%;
min-width: 50px;
accent-color: white;
`