import { Component, PropsWithChildren, Ref, RefObject } from "react";
import MessageWrapperRefContext, { MessageWrapperRefContextType } from "../models/MessageWrapperRefContext";

interface MessageWrapperRefProviderState {
    ref: RefObject<HTMLDivElement> | null;
}

class MessageWrapperRefProvider extends Component<PropsWithChildren, MessageWrapperRefProviderState> {

    constructor(props: PropsWithChildren) {
        super(props);
        this.state = {
            ref: null
        }
    }

    setMessageWrapperRef = (ref: RefObject<HTMLDivElement>) => {
        this.setState({ ref: ref });
    }

    render = () => {
        return (
            <MessageWrapperRefContext.Provider value={{
                messageWrapperRef: this.state.ref,
                setMessageWrapperRef: this.setMessageWrapperRef
            
            }}>
                {this.props.children}
            </MessageWrapperRefContext.Provider>
        )
    }
}

export default MessageWrapperRefProvider