import { styled } from "styled-components";

const ControlButtonStyled = styled.div`
display: flex;
transition: transform 0.1s, box-shadow 0.1s;
outline: none; 
user-select: none;
-webkit-tap-highlight-color: transparent;

&:active {
    transform: translateY(2px); /* Move the button down slightly */
    box-shadow: 0 1px 2px rgba(0,0,0,0.2); /* Reduce the shadow to give a pressed appearance */
}
`

export default ControlButtonStyled;