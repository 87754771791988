import { useEffect, useCallback } from 'react';

export const useKeyDown = (callback: (T?: any) => void, keys: KeyboardKey[]) => {

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    const pressedKey = keys.find((key) => event.key === key);

    if (pressedKey) {
      event.preventDefault();
      callback(pressedKey);
    }
  }, 
  [callback, keys]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export enum KeyboardKey {
    escape = 'Escape',
    arrowLeft = 'ArrowLeft',
    arrowRight = 'ArrowRight'
  }