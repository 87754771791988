import './wdyr'

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ENVIRONMENT } from './services/utils';
import setupPolyfills from './polyfills'

setupPolyfills();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

if (ENVIRONMENT !== "production") {
  reportWebVitals(console.log);
}

