import React, { PropsWithChildren, ReactNode } from 'react';
import { styled } from 'styled-components';

import { ReactComponent as IoBug } from './IoBug.svg'
import { ReactComponent as IoPause } from './IoPause.svg'
import { ReactComponent as IoPlay } from './IoPlay.svg'
import { ReactComponent as IoScan } from './IoScan.svg'
import { ReactComponent as IoVolumeHigh } from './IoVolumeHigh.svg'
import { ReactComponent as IoVolumeLow } from './IoVolumeLow.svg'
import { ReactComponent as IoVolumeMedium } from './IoVolumeMedium.svg'
import { ReactComponent as IoVolumeMute } from './IoVolumeMute.svg'
import { ReactComponent as LanguageChange } from './LanguageChangeIcon.svg'
import { ReactComponent as Cookie } from './Cookie.svg'

const IconWrapper: React.FC<PropsWithChildren> = ({ children, ...props  }) => {
    return (
        <IconWrapperStyled {...props}>
            {children}
        </IconWrapperStyled>
    );
};

const IconWrapperStyled = styled.div.attrs({
    className: 'fai-chat-icon',
})`
display: flex;
justify-content: center;
`

export default IconWrapper;

export const IoBugIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoBug/></IconWrapper>;
export const IoPauseIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoPause/></IconWrapper>;
export const IoPlayIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoPlay/></IconWrapper>;
export const IoScanIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoScan/></IconWrapper>;
export const IoVolumeHighIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoVolumeHigh/></IconWrapper>;
export const IoVolumeLowIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoVolumeLow/></IconWrapper>;
export const IoVolumeMediumIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoVolumeMedium/></IconWrapper>;
export const IoVolumeMuteIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><IoVolumeMute/></IconWrapper>;
export const LanguageChangeIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><LanguageChange/></IconWrapper>;
export const CookieIcon = (props : React.HTMLAttributes<HTMLDivElement>) => <IconWrapper {...props}><Cookie/></IconWrapper>;