import { forwardRef } from "react"
import styled from "styled-components"
import ControlsWrapper from "./ControlsWrapper"
import { InterviewStatus } from "../apiClient"
import { ConnectionState } from "../models/ConnectionStateEnum"

let Footer = forwardRef<HTMLDivElement, FooterProps>((props: FooterProps, ref) => {
    return (
      <FooterStyled ref={ref}>
        <ControlsWrapper 
          onMessageSent={props.onMessageSent}
          onMessageAggregateSent={props.onAggregateMessageSent}
          disabled={props.disabled}
          interviewStatus={props.interviewStatus}
          enabledText={props.enabledText}
          disabledText={props.disabledText} 
          conversationEndText={props.conversationEndText}
          connectionState={props.connectionState}/>
      </FooterStyled>
    )
  })

  interface FooterProps {
    onMessageSent: (msg: string) => void,
    onAggregateMessageSent: (messageId: number, isStart : boolean) => void,
    disabled:boolean,
    interviewStatus: InterviewStatus,
    enabledText: string,
    disabledText: string,
    conversationEndText: string,
    connectionState: ConnectionState
  }
  
  let FooterStyled = styled.div`
  max-height: 144px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 46.67%);
  box-sizing: border-box;
  flex-grow: 0;
  bottom: 0;
  max-width: 784px;

  padding-bottom: 0.5rem;
  `

  export default Footer