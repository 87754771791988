import { Component, PropsWithChildren } from 'react';
import ChatStateContext, { ChatContextState } from '../models/ChatStateContext';
import { InterviewStage, InterviewStatus } from '../apiClient';
import { ConnectionState } from '../models/ConnectionStateEnum';

interface ChatStateProviderProps {
    interviewUid: string;
}

class ChatStateProvider extends Component<PropsWithChildren<ChatStateProviderProps>, ChatContextState> {
    
    constructor(props: PropsWithChildren<ChatStateProviderProps>) {
        super(props); 
        this.state = {
            progress: 0,
            interviewUid: props.interviewUid,
            greetingModel: {
                greetingText: "",
                acceptButtonText: "",
                rejectButtonText: "",
                languages: []
            },
            assistantName: "",
            assistantImageUrl: "",
            chatLeaveTexts: {
                leave_button_text: "",
                leave_modal_text: "",
                leave_modal_title: "",
                leave_modal_button_cancel: "",
                leave_modal_button_leave: "",
                leave_farewell: ""
            },
            finalScreenData: undefined,
            isLoading: true,
            footerText: "",
            isInputBlocked: true,
            currentQuestionId: "",
            interviewStage: InterviewStage.Greeting,
            pinnedMessage: undefined,
            connectionState: ConnectionState.Connected,
            reconnectionText: "",
            disconnectedText: "",
            interviewStatus: InterviewStatus.Open,
            statusText: "",
            interviewLanguage: "",
            agentOnlineStatus: "",
            chatEnebledText: "",
            chatDisabledText: "",
            conversationNaturalnessScoreRequestText: "",
            chatConversationEndText: "",
            showDebugMessages: false,
            isTestInterview: false,
            score: null,
        };
    }

    render() {
        return (
            <ChatStateContext.Provider value={{
                progress: this.state.progress,
                interviewUid: this.state.interviewUid,
                greetingModel: this.state.greetingModel,
                assistantName: this.state.assistantName,
                assistantImageUrl: this.state.assistantImageUrl,
                chatLeaveTexts: this.state.chatLeaveTexts,
                finalScreenData: this.state.finalScreenData,
                isLoading: this.state.isLoading,
                footerText: this.state.footerText,
                isInputBlocked: this.state.isInputBlocked,
                currentQuestionId: this.state.currentQuestionId,
                interviewStage: this.state.interviewStage,
                pinnedMessage: this.state.pinnedMessage,
                connectionState: this.state.connectionState,
                reconnectionText: this.state.reconnectionText,
                disconnectedText: this.state.disconnectedText,
                interviewStatus: this.state.interviewStatus,
                statusText: this.state.statusText,
                interviewLanguage: this.state.interviewLanguage,
                agentOnlineStatus: this.state.agentOnlineStatus,
                chatEnebledText: this.state.chatEnebledText,
                chatDisabledText: this.state.chatDisabledText,
                conversationNaturalnessScoreRequestText: this.state.conversationNaturalnessScoreRequestText,
                chatConversationEndText: this.state.chatConversationEndText,
                showDebugMessages: this.state.showDebugMessages,
                isTestInterview: this.state.isTestInterview,
                score: this.state.score,
                
                setProgress: (progress) => {
                    this.setState({ progress: progress });
                },
                setInterviewUid: (interviewUid) => {
                    this.setState({ interviewUid: interviewUid });
                },
                setGreetingModel: (greetingModel) => {
                    this.setState({ greetingModel: greetingModel });
                },
                setAssistantName: (assistantName) => {
                    this.setState({ assistantName: assistantName });
                },
                setAssistantImageUrl: (assistantImageUrl) => {
                    this.setState({ assistantImageUrl: assistantImageUrl });
                },
                setChatLeaveTexts: (chatLeaveTexts) => {
                    this.setState({ chatLeaveTexts: chatLeaveTexts });
                },
                setFinalScreen: (finalScreenData) => {
                    this.setState({ 
                        finalScreenData: finalScreenData,
                        isLoading: false
                    });
                },
                setIsLoading: (isLoading) => {
                    this.setState({ isLoading: isLoading });
                },
                setChatState: (chatState) => {
                    this.setState(chatState);
                },
                setIsInputBlocked: (isInputBlocked) => {
                    this.setState({ isInputBlocked: isInputBlocked });
                },
                setCurrentQuestionId: (currentQuestionId) => {
                    this.setState({ currentQuestionId: currentQuestionId });
                },
                setInterviewStage: (interviewStage) => {
                    this.setState({ interviewStage: interviewStage });
                },
                setPinnedMessage: (pinnedMessage) => {
                    this.setState({ pinnedMessage: pinnedMessage });
                },
                setConnectionState: (connectionState) => {
                    this.setState({ connectionState: connectionState });
                },
                setReconnectionText: (reconnectionText) => {
                    this.setState({ reconnectionText: reconnectionText });
                },
                setDisconnectedText: (disconnectedText) => {
                    this.setState({ disconnectedText: disconnectedText });
                },
                setInterviewStatus: (interviewStatus) => {
                    this.setState({ interviewStatus: interviewStatus });
                },
                setStatusText: (statusText) => {
                    this.setState({ statusText: statusText });
                },
                setInterviewLanguage: (interviewLanguage) => {
                    this.setState({ interviewLanguage: interviewLanguage });
                },
                toggleShowDebugMessages: () => {
                    let newValue = !this.state.showDebugMessages;
                    localStorage.setItem("showDebugMessages", newValue.toString());
                    this.setState({ showDebugMessages: newValue });
                },
                setInterviewScore: (score) => {
                    this.setState({score: score});
                },
            }}>
                {this.props.children}
            </ChatStateContext.Provider>);
    }
};

export default ChatStateProvider;