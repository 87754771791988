import * as React from 'react';
import { styled } from 'styled-components';
import puzzleImage from '../public/puzzle.svg'
import { makeRedirect } from '../services/utils';

export interface SurveyClosedFinalScreenProps {
  message: string,
  redirectUrl?: string | undefined;
  redirectMessage?: string | undefined;
}

const SurveyClosedFinalScreen = (props: SurveyClosedFinalScreenProps) => {

  const [counter, setCounter] = React.useState(5);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!!props.redirectUrl) {
      if (counter > 0) {
        timeoutId = setTimeout(() => setCounter(x => x - 1), 1000);
      } else {
        makeRedirect(props.redirectUrl, 0);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [counter, props.redirectUrl]);

  return (
    <FinalScreenContainerStyled>
      <FinalScreenImageStyled $image={puzzleImage} />
      <FinalScreenTextStyled dir="auto">
        {props.message}
      </FinalScreenTextStyled>
      {!!props.redirectMessage && (
        <FinalScreenTextStyled>
          {props.redirectMessage.replace(/<Countdown.*\/>/gim, `${counter}`)}
        </FinalScreenTextStyled>
      )}
    </FinalScreenContainerStyled>
  );
}

const FinalScreenContainerStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
`

interface FinalScreenImageStyledProps {
  $image: string;
}

const FinalScreenImageStyled = styled.div<FinalScreenImageStyledProps>`
  background-image: url(${props => props.$image});
  background-size: cover;
  font-size: 85%;
  @media (min-width: 800px) {
    font-size: 75%;
  };
  height: 30em;
  width: 22em;
`

const FinalScreenTextStyled = styled.div`
  margin: 0 1em;
`

export default SurveyClosedFinalScreen