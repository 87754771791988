import { useEffect, useState } from "react";
import styled from "styled-components";
import { MediaMeta } from "../models/ImageExpandContext";

const ImageCarousel = (props: ImageCarouselProps) => {
    const [limits, setLimits] = useState<{[id: string]: ImageCarouselLimits}>({})
    const [originalLimits, setOriginalLimits] = useState<{[id: string]: ImageCarouselLimits}>({})
    const [isZoomed, toggleZoomed] = useState<boolean>(false);
    const [zoomOffset, setZoomOffset] = useState<ImageCarouselZoomOffset>({x:0, y:0});

    useEffect(() => {
      let limitDict: {[id: string]: ImageCarouselLimits} = {}

      for (const image of props.carouselImages) {
        let limitWidth: boolean = false;
        let limitHeight: boolean = false;
        if (image.aspectRatio > 1)
          limitWidth = true;
        else
          limitHeight = true;

        limitDict[image.url] = {
          height: limitHeight,
          width: limitWidth
        };
      }

      setLimits(limitDict);
      setOriginalLimits(limitDict);
    }, [])

    return (
        <>
            <CarouselStyled className="carousel" $currentIndex={props.currentIndex} $carouselImageCount={props.carouselImages.length} $zoomed={isZoomed}>
                {props.carouselImages.map(x => {
                    const imageLimits = limits[x.url] ?? {width: false, height: false}
                    return (
                        <CarouselSlideStyled className="slide" onClick={props.onCloseClick}>
                            <CarouselSlideImageStyled 
                            onClick={(e:any) => {
                              e.stopPropagation();
                              if (isZoomed){
                                setLimits(originalLimits);
                                toggleZoomed(false);
                              }
                              else {
                                let newLimits = {...limits}
                                newLimits[x.url] = {width: false, height: false}
                                setLimits(newLimits)
                                toggleZoomed(true);
                              }
                              
                            }}
                            src={x.url} draggable="false"
                            $limitWidth={imageLimits.width} $limitHeight={imageLimits.height} 
                            offsetX={zoomOffset.x} offsetY={zoomOffset.y} 
                            />
                        </CarouselSlideStyled>);
                })}
            </CarouselStyled>
            {props.carouselImages.length > 1 && <ImageExpandNavigationStyled $currentIndex={props.currentIndex}>
                {props.carouselImages.map((_, index) => <ImageExpandNavigationElementStyled data-index={index} onClick={() => props.onNavigationElementClick(index)} />)}
            </ImageExpandNavigationStyled>}
        </>
    )
}

export default ImageCarousel

interface ImageCarouselZoomOffset {
  x: number,
  y: number
}

interface ImageCarouselLimits {
  width: boolean,
  height: boolean
}

interface ImageCarouselProps {
    currentIndex: number,
    carouselImages: MediaMeta[],
    onCloseClick: () => void,
    onNavigationElementClick: (index: number) => void
}

const ImageExpandNavigationElementStyled = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid var(--element-color);
  border-radius: 0.5rem;
  
  &:hover {
    background-color: var(--element-color);
  }
`

const ImageExpandNavigationStyled = styled.nav<ImageExpandNavigationStyledProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  z-index: 13;

  & div[data-index="${props => props.$currentIndex}"] {
    background-color: var(--element-color);
  }
`

interface ImageExpandNavigationStyledProps {
  $currentIndex: number;
}


const CarouselStyled = styled.div<CarouselStyledProps>`
  z-index: 11;
  display: flex;
  width: ${props => props.$carouselImageCount}00%;
  height: 100%;
  position: absolute;
  left: 0;
  --current-translate: -${props => props.$currentIndex}00%;
  ${props => props.$zoomed ? `overflow: auto;` : ""}
`

interface CarouselStyledProps {
  $currentIndex: number,
  $carouselImageCount: number,
  $zoomed: boolean
}

const CarouselSlideStyled = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
  transform: translateX(var(--current-translate));
  transition: all 250ms ease-out 0s;
`

const CarouselSlideImageStyled = styled.img.attrs<CarouselSlideImageStyledOffsetProps>(props => ({
  style: {
    transform: `translate(${props.offsetX}px, ${props.offsetY}px)`
  }
}))<CarouselSlideImageStyledProps>`
  margin: auto;
  user-select:none;
  ${props => props.$limitWidth ? "max-width: 90%;" : ""}
  ${props => props.$limitHeight ? "max-height: 90%;" : ""}
  cursor: zoom-in;
`

interface CarouselSlideImageStyledOffsetProps {
  offsetX: number,
  offsetY: number
}

interface CarouselSlideImageStyledProps {
  $limitWidth: boolean,
  $limitHeight: boolean,
  
}