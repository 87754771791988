import { useEffect, useState } from "react"
import styled from "styled-components"
import Spinner from "./Spinner";

const ImageBase = (props: ImageBaseProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let preloadedImg = new Image()
        preloadedImg.onload = () => {
            setIsLoading(false)
        }
        preloadedImg.src = props.src;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    let width: string = "100%";
    let height: string = "auto";

    if (isLoading)
        return (<Spinner />)
    else
        return (<ImageBaseStyled src={props.src} $width={width} $height={height} />)
}

export default ImageBase

interface ImageBaseProps {
    src: string,
    sizes: { w: number, h: number }
}

const ImageBaseStyled = styled.img<ImageBaseStyledProps>`
  display: flex;
  width: ${props => props.$width};
  height: ${props => props.$height};;
  `;

interface ImageBaseStyledProps {
    $width: string,
    $height: string
}