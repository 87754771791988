import React, { createRef, KeyboardEvent, MouseEvent, FormEvent } from "react";
import styled from "styled-components";
import MessagesContext from "../models/MessagesContext";
import { InterviewStatus } from "../apiClient";
import { MessageSide } from "../models/Message";
import { ConnectionState } from "../models/ConnectionStateEnum";

class ControlsWrapper extends React.Component<ControlsWrapperProps, ControlsWrapperState> {

    static contextType?: React.Context<any> | undefined = MessagesContext;
    context!: React.ContextType<typeof MessagesContext>

    state: Readonly<ControlsWrapperState> = {
        inputValue: "",
        rows: 1,
        lineHeight: 24,
        textareaHeightOffset: 0
    }

    inputRef = createRef<HTMLTextAreaElement>();
    inputCloneRef = createRef<HTMLDivElement>();
    inputWrapperRef = createRef<HTMLDivElement>();

    componentDidMount() {
        let textarea = this.inputRef.current!;
        let taLineHeight = Number.parseInt(window.getComputedStyle(textarea).getPropertyValue('line-height'));
        let taHeightPaddingTop = Number.parseInt(window.getComputedStyle(textarea).getPropertyValue('padding-top'));
        let taHeightPaddingBottom = Number.parseInt(window.getComputedStyle(textarea).getPropertyValue('padding-bottom'));
        this.setState({
            lineHeight: taLineHeight,
            textareaHeightOffset: taHeightPaddingTop + taHeightPaddingBottom
        })
    }

    componentDidUpdate(prevProps: Readonly<ControlsWrapperProps>, prevState: Readonly<{ inputValue: string }>, snapshot?: any): void {
        if (prevState.inputValue !== this.state.inputValue)
            this.setInputHeight();
        if (prevProps.disabled === true && this.props.disabled === false)
            this.inputRef.current?.focus();
    }

    onInput: (e: FormEvent<HTMLTextAreaElement>) => void = (e) => {
        const lastMessage = this.context.messages[this.context.messages.length - 1]

        if(lastMessage.side == MessageSide.User){
            if (e.currentTarget.value === "") {
                this.props.onMessageAggregateSent(lastMessage.id as number, true);
            } 
            if (this.state.inputValue === "" && e.currentTarget.value !== "") {
                this.props.onMessageAggregateSent(lastMessage.id as number, false);
            }
        }

        this.setInputHeight();
        this.setState({ inputValue: e.currentTarget.value })
    }

    countLines = () => {
        if (!this.inputCloneRef.current || !this.inputRef.current)
            return 1;

        let text: string = this.inputRef.current.value;
        const linesFromBreaks = (text.match(/\n/g) || []).length + 1; // Count explicit line breaks
        text = text.at(-1) === '\n' ? text + '@' : text;

        this.inputCloneRef.current.innerHTML = text;

        const totalHeight = this.inputCloneRef.current.scrollHeight;

        this.inputCloneRef.current.innerHTML = ""

        const lineHeight = parseInt(window.getComputedStyle(this.inputRef.current).lineHeight, 10);
        const linesFromHeight = Math.ceil(totalHeight / lineHeight);

        return Math.max(linesFromBreaks, linesFromHeight);
    }

    setInputHeight: () => void = () => {
        let lines = this.countLines();
        this.setState({ rows: lines }, () => {
            this.inputWrapperRef.current?.scrollTo(0, this.inputWrapperRef.current.scrollHeight * 2);
        });
    }

    keyDownFilter: (e: KeyboardEvent<HTMLTextAreaElement>) => Promise<void> = async (e) => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && (e.ctrlKey || e.shiftKey)) {
            e.preventDefault();
            this.setInputHeight();
            this.setState({ inputValue: this.state.inputValue + '\n' })
        }
        if ((e.code === "Enter" || e.code === "NumpadEnter") && (e.ctrlKey || e.shiftKey) === false) {
            e.preventDefault();
            await this.sendMessageAsync();
        }
    }

    sendMessageByClick: (e: MouseEvent<HTMLDivElement>) => void = async (e) => {
        await this.sendMessageAsync();
    }

    sendMessageAsync: () => Promise<void> = async () => {
        if (this.props.disabled || !this.state.inputValue || this.props.connectionState !== ConnectionState.Connected)
            return;
            
        this.props.onMessageSent(this.state.inputValue);
        this.setState({ inputValue: "", rows: 1 }, () => {
            this.inputRef.current?.focus();
        });
    }

    inputPlaceholderText = () => {
        if (this.props.disabled) {
            if (this.props.interviewStatus !== InterviewStatus.Open)
                return this.props.conversationEndText

            return this.props.disabledText
        }
        return this.props.enabledText
    }

    render() {
        return (
            <ControlsWrapperStyled>
                <InputWrapperStyled ref={this.inputWrapperRef}>
                    <InputStyled ref={this.inputRef}
                        rows={this.state.rows}
                        onInput={this.onInput}
                        value={this.state.inputValue}
                        onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => { this.keyDownFilter(e) }}
                        placeholder={this.inputPlaceholderText()}
                        disabled={this.props.disabled}
                    />
                </InputWrapperStyled>
                {this.inputRef.current && <InputCloneStyled ref={this.inputCloneRef} 
                    fontSize={window.getComputedStyle(this.inputRef.current).fontSize}
                    fontFamily={window.getComputedStyle(this.inputRef.current).fontFamily}
                    width={this.inputRef.current.clientWidth}
                    lineHeight={window.getComputedStyle(this.inputRef.current).lineHeight}
                />}
                <SendButtonStyled onClick={this.sendMessageByClick}>
                    <SvgStyled $disabled={this.props.disabled} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_36_1085)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.5917 14.7125L14.3808 19.25H19.25C19.6642 19.25 20 19.5858 20 20C20 20.4143 19.6642 20.75 19.25 20.75H14.3808L13.5917 25.2876L25.9294 20L13.5917 14.7125ZM12.9887 20L12.0637 14.6809C11.9889 14.2509 12.128 13.8114 12.4367 13.5027C12.8244 13.1149 13.4093 13.0023 13.9133 13.2183L27.3157 18.9622C27.7308 19.1401 28 19.5484 28 20C28 20.4517 27.7308 20.86 27.3157 21.0379L13.9133 26.7817C13.4093 26.9978 12.8244 26.8851 12.4367 26.4974C12.128 26.1887 11.9889 25.7492 12.0637 25.3192L12.9887 20Z" fill="#2C2C2E" />
                        </g>
                        <defs>
                            <clipPath id="clip0_36_1085">
                                <rect width="16" height="16" fill="white" transform="translate(12 12)" />
                            </clipPath>
                        </defs>
                    </SvgStyled>
                </SendButtonStyled>
            </ControlsWrapperStyled>
        )
    }
}

export default ControlsWrapper

interface ControlsWrapperState {
    inputValue: string,
    rows: number,
    lineHeight: number,
    textareaHeightOffset: number
}
interface ControlsWrapperProps {
    onMessageSent: (msg: string) => void,
    onMessageAggregateSent: (messageId : number, isStartDown : boolean) => void,
    disabled: boolean,
    interviewStatus: InterviewStatus,
    enabledText:string,
    disabledText:string,
    conversationEndText: string,
    connectionState: ConnectionState
}

let ControlsWrapperStyled = styled.div`
width: 100%;
display: flex;
position: relative;
align-items: center;
`

let SvgStyled = styled.svg<SvgStyledProps>`
opacity: 0.3;
&:hover {
    ${props => props.$disabled ? "" : "opacity: 1;"}
}
`

interface SvgStyledProps {
    $disabled: boolean
}

let SendButtonStyled = styled.div`
  display: flex;
  position: absolute;
  right: 8px;
  align-items: center;
  `

let InputWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
  border-top: 1px solid #E5E5E5;
  color: #2C2C2E;
  resize: none;
  outline: none;
  max-height: 144px;
  overflow: auto;
  box-shadow: 0px 0px 16px rgba(6, 23, 43, 0.08);
  background: white;
  
  scrollbar-width: thin;
  scrollbar-color: #249de4 #f5f5f5;

  @media (min-width: 800px) {
    border-radius: 8px;
    border: 1px solid #E5E5E5;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #E5E5E5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  `

let InputStyled = styled.textarea`
margin: auto;
width: 100%;
padding: 8px 16px;
margin-right: 40px;
border: none;
resize: none;
outline: none;
overflow-y: hidden;

display: flex;
align-self: flex-start;

font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
font-size: 16px;
line-height: 24px;
font-style: normal;

--scrollbar-width: thin;
--scrollbar-color: #249de4 #f5f5f5;
  
  &::placeholder {
    color: #2C2C2E;
    opacity: 0.4;
  }
  `


  interface InputCloneStyledProps {
    fontSize: string,
    fontFamily: string,
    width: number,
    lineHeight: string
  }

  let InputCloneStyled = styled.div<InputCloneStyledProps>`
  visibility: hidden;
  position: absolute;
  height: auto;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: ${props => props.fontSize};
  font-family: ${props => props.fontFamily};
  width: ${props => props.width + 'px'};
  line-height: ${props => props.lineHeight};
  `