export interface Message {
    id?: number,
    temp_id?: number,
    side: MessageSide,
    payload: MessagePart[],
    reaction?: string | null
}

export interface MessagePart {
    type: MessagePartType,
    data: string
}

export interface MediaMessagePart extends MessagePart {
    aspectRatio: number,
    width: number,
    height: number,
    previewUrl: string,
}

export enum MessagePartType {
    Text = 1,
    Image = 2,
    Video = 3
}

export enum MessageSide {
    Bot = 1,
    User = 2,
    System = 3
}
