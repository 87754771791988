import React, { useContext, useEffect } from 'react';
import { styled } from 'styled-components';
import { useChatClient, useChatClientWithCredentials } from '../services/utils';
import ChatStateContext from '../models/ChatStateContext';
import { ConnectionState } from '../models/ConnectionStateEnum';
import MessagesContext from '../models/MessagesContext';
import Modal from './Modal/Modal';
import BackdropStyled, { BackdropColor } from './Modal/BackdropStyled';

interface SystemMessageProps {
    message: string;
    tooltipMessage?: string;
}

const SystemMessage: React.FC<SystemMessageProps> = (props: SystemMessageProps) => {

    const messagesContext = useContext(MessagesContext);
    const [isTooltipVisible, toggleTooltipVisibility] = React.useState(false);

    if (!props.message)
        return null;

    messagesContext.scrollToBottom();

    return (
        <SystemMessageStyled className="message-system" hasTooltip={!!props.tooltipMessage} onClick={() => toggleTooltipVisibility(!isTooltipVisible)} >
            {props.message}
            {props.tooltipMessage && isTooltipVisible && 
                <Modal>
                    <BackdropStyled color={BackdropColor.Transparent} />
                    <SystemMessageTooltip tooltipMessage={props.tooltipMessage} onClick={() => toggleTooltipVisibility(!isTooltipVisible)} />
                </Modal>
            }
        </SystemMessageStyled>
    );
};

export default SystemMessage;

const SystemMessageStyled = styled.p<{hasTooltip: boolean}>`
    color: #fffffff0;
    background-color: #707579f0;
    padding: .28125rem .625rem;
    -moz-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: break-word;
    border-radius: 8px;
    white-space: pre-wrap;

    max-width: 80%;
    align-self: center;
    text-align: center;

    ${hasTooltip => hasTooltip ? 'cursor: pointer;' : ''}
    ${hasTooltip => hasTooltip ? 'text-decoration: underline;' : ''}
`

interface SystemMessageTooltipProps {
    tooltipMessage: string;
    onClick: () => void;
}

const SystemMessageTooltip = (props: SystemMessageTooltipProps) => {
    return (
        <SystemMessageTooltipStyled onClick={props.onClick}>
            {props.tooltipMessage}
        </SystemMessageTooltipStyled>
    )
}

const SystemMessageTooltipStyled = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    z-index: 1000;
    width: auto;
    height: auto;
    max-height: 400px;
    white-space: break-spaces;
    text-align: justify;
    overflow-y: scroll;
`