import { styled } from "styled-components";

const BackdropStyled = styled.div<{color: BackdropColor}>`
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background-color: ${props => props.color};
opacity: 0.8;
`

export default BackdropStyled

export enum BackdropColor {
    Black = "#000000",
    White = "#FFFFFF",
    Grey = "#2C2C2E",
    Transparent = "#00000000"
}