import React from "react";
import { Message } from "./Message";
import { HubMessageText } from "../apiClient";
import { DebugMessage } from "./DebugMessage";

export class MessagesContextType {
    public messages: Message[] = [];
    public isModeratorTyping: boolean = false;
    public debugMessages: Map<number, DebugMessage[]> = new Map<number, DebugMessage[]>();

    addMessageAsync: (msg: Message) => Promise<void> = (msg) => Promise.resolve()
    addMessageInstantAsync: (msg: Message) => Promise<number> = (msg) => Promise.resolve(0)
    setIsModeratorTypingAsync: (isTyping: boolean) => Promise<void> = (isTyping) => Promise.resolve()
    addReaction: (userMessageSentId: number, reaction: string) => Promise<void> = (userMessageSentId, reaction) => Promise.resolve()
    syncMessage: (oldMessageId: number, message: HubMessageText) => Promise<void> = (oldMessageId, message) => Promise.resolve()
    clear: () => Promise<void> = () => Promise.resolve()
    scrollToBottom: () => void = () => {}
    addDebugMessage: (newMessage: DebugMessage) => Promise<void> = (newMessage) => Promise.resolve()
    getDebugMessages: (targetMessageId: number) => DebugMessage[] = (targetMessageId) => []

    constructor(messages: Message[]) {
        this.messages = messages;
    }
}

const MessagesContext = React.createContext<MessagesContextType>(new MessagesContextType([]));

export default MessagesContext;