import { InterviewRole, InterviewStage, InterviewStatus, MessageContinueRequestModel, MessageContinueResponseModel } from "../../apiClient";
import { Message, MessagePartType, MessageSide } from "../../models/Message";
import { makeRedirect } from "../utils";
import { MessagesContextType } from '../../models/MessagesContext'
import { ChatStateContextType } from "../../models/ChatStateContext";
import { HubConnection } from "@microsoft/signalr";
import getAcceptedLanguages from "./ChatHub_GetAcceptedLanguages";
import { processStimuliResponseModelAsync } from "../chatHubFunctions/processStimulusMessage";
import { ConnectionState } from "../../models/ConnectionStateEnum";

export default async function continueInterview(
  message: MessageContinueRequestModel,
  messagesContext: MessagesContextType,
  chatStateContext: ChatStateContextType,
  connection: HubConnection,
  signal: AbortSignal): Promise<void> {

  let continueResponse: MessageContinueResponseModel = await connection.invoke('Continue', message, signal);

  await messagesContext.clear();
  if (continueResponse.isError) {
    chatStateContext.setFinalScreen({
      name: "SurveyClosed",
      data: {
        message: continueResponse.messages[0].message,
        redirectUrl: continueResponse.redirectUrl || undefined,
        redirectMessage: !!continueResponse.redirectUrl ? continueResponse.messages[1].message : undefined
      }
    });
    return;
  }

  const languages = await getAcceptedLanguages(connection, signal);

  const showDebugMessages = localStorage.getItem("showDebugMessages") === "true"

  chatStateContext.setChatState({
    assistantName: continueResponse.assistantName,
    assistantImageUrl: continueResponse.assistantImageUrl,
    chatLeaveTexts: continueResponse.leaveTexts,
    greetingModel: {
      greetingText: continueResponse.greetingText,
      acceptButtonText: continueResponse.acceptButtonText,
      rejectButtonText: continueResponse.rejectButtonText,
      languages: languages.accepted_languages
    },
    interviewUid: chatStateContext.interviewUid,
    progress: continueResponse.progress,
    isLoading: false,
    footerText: continueResponse.footerText,
    isInputBlocked: continueResponse.interviewStatus === InterviewStatus.Complete || continueResponse.needAnswer,
    currentQuestionId: continueResponse.currentQuestionId,
    interviewStage: continueResponse.interviewStage,
    connectionState: ConnectionState.Connected,
    reconnectionText: continueResponse.reconnectingText,
    disconnectedText: continueResponse.connectionUnavailableText,
    interviewStatus: continueResponse.interviewStatus,
    statusText: "",
    interviewLanguage: continueResponse.interviewLanguage,
    chatDisabledText: continueResponse.chatDisabledText,
    agentOnlineStatus: continueResponse.agentOnlineStatus,
    chatEnebledText: continueResponse.chatEnabledText,
    showDebugMessages: showDebugMessages,
    conversationNaturalnessScoreRequestText: continueResponse.conversationNaturalnessScoreRequestText,
    chatConversationEndText: continueResponse.chatConversationEndText,
    isTestInterview: continueResponse.isTestInterview,
    score: continueResponse.score,
  });

  for (let i = 0; i < continueResponse.messages.length; i++) {
    let messageItem = continueResponse.messages[i];
    if (messageItem.stimulus_data)
      await processStimuliResponseModelAsync(messageItem.id, messageItem.stimulus_data, messagesContext, chatStateContext, true);
    else {
      let message: Message = {
        id: messageItem.id,
        reaction: messageItem.reaction,
        side: messageItem.author === InterviewRole.User ? MessageSide.User : MessageSide.Bot,
        payload: [{
          type: MessagePartType.Text,
          data: messageItem.message
        }]
      }
      await messagesContext.addMessageInstantAsync(message);
    }
  }

  if (continueResponse.redirectUrl) {
    await makeRedirect(continueResponse.redirectUrl);
    return;
  }
}