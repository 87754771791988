import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ImageExpandContext, { MediaMeta } from "../models/ImageExpandContext";
import { MessageSide } from "../models/Message";
import MessageWrapperRefContext from "../models/MessageWrapperRefContext";
import { CHAT_MESSAGE_MAX_WIDTH } from "../services/utils";
import ImageBase from "./ImageBase";

interface ImageGalleryProps {
  imageMetas: MediaMeta[],
  gap: number,
  text?: string,
  side: MessageSide,
  last: boolean
}

const ImageGallary = (props: ImageGalleryProps) => {
  const MIN_GALLERY_DOM_WIDTH = CHAT_MESSAGE_MAX_WIDTH * 0.6;

  const [imageGroups, setImageGroups] = useState<MediaMeta[][]>([])
  const [imagesRowWidth, setImagesRowWidth] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rerenderUid, setRerenderUid] = useState<string>("")
  const gallaryRef = useRef<HTMLDivElement>(null)
  const gallaryWrapperRef = useRef<HTMLDivElement>(null)

  const imageExpandContext = useContext(ImageExpandContext);
  const messageWrapperRef = useContext(MessageWrapperRefContext);

  const resizeHandler = () => {
    setRerenderUid(crypto.randomUUID());
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    recalcImageSizes();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    }
  }, [])

  const recalcImageSizes = useCallback(() => {
    if (imageGroups.length === 0) {
      //делим изображения на группы по 2 максимум
      //если картинок нечётное число и > 1, то выделяем либо первую, либо последнюю в отдельную группу (будет крупной)
      //выбор зависит от соотношения сторон этих картинок - выбираем с большим w / h, чтобы картинка занимала меньше места по вертикали
      let groups: MediaMeta[][] = []

      if (props.imageMetas.length === 1) {
        groups.push(props.imageMetas)
      }
      else if (props.imageMetas.length % 2 === 0) {
        for (let i = 0; i < props.imageMetas.length / 2; i++) {
          groups.push(props.imageMetas.slice(i * 2, 2 + i * 2));
        }
      }
      else {
        let maximizeFirstImage: boolean = false;
        const firstElement = props.imageMetas[0];
        const lastElement = props.imageMetas.at(-1)!;

        const firstElementSuitableForExtend = firstElement.width > MIN_GALLERY_DOM_WIDTH;
        const lastElementSuitableForExtend = lastElement.width > MIN_GALLERY_DOM_WIDTH;

        if (firstElementSuitableForExtend && lastElementSuitableForExtend)
          maximizeFirstImage = firstElement.aspectRatio >= lastElement.aspectRatio;
        else if (firstElementSuitableForExtend && !lastElementSuitableForExtend)
          maximizeFirstImage = true;
        else if (!firstElementSuitableForExtend && lastElementSuitableForExtend)
          maximizeFirstImage = false;
        else {
          maximizeFirstImage = firstElement.width >= lastElement.width;
        }

        let index: number = 0;
        if (maximizeFirstImage) {
          groups[0] = props.imageMetas.slice(index * 2, index * 2 + 1);
          index = 1;
        }
        for (index; index < Math.ceil(props.imageMetas.length / 2); index++) {
          let indexStart = maximizeFirstImage ? index * 2 - 1 : index * 2;
          let group: MediaMeta[] = props.imageMetas.slice(indexStart, indexStart + 2);
          groups.push(group);
        }
      }

      let messageWrapperWidth : number | undefined = messageWrapperRef?.messageWrapperRef?.current?.clientWidth;
      let descriptionDivWidth : number =  gallaryRef.current!.clientWidth;

      //берём наибольшее из части всей шиирны и длиной сообщения описания стимула
      let divWidth = descriptionDivWidth;
      if (messageWrapperWidth)
        divWidth = Math.max(messageWrapperWidth * 0.6, descriptionDivWidth)

      const groupsSumWidth: number[] = groups.map(g => g.map(x => x.width).reduce((acc, next) => acc + next));
      const galleryWidth: number = Math.min(divWidth, ...groupsSumWidth);

      let maxImageRowWidth = 0;
      groups.forEach(group => {
        const overallRatio = group.map(x => x.aspectRatio).reduce((acc, next) => acc + next);
        let h = Math.min(...group.map(x => x.height), (galleryWidth - (group.length - 1) * props.gap) / overallRatio);
        let w = h * overallRatio;
        if (w > maxImageRowWidth)
          maxImageRowWidth = w;
      });

      setImageGroups(groups);
      setImagesRowWidth(maxImageRowWidth);
    }
  }, [messageWrapperRef])

  useEffect(() => {
    recalcImageSizes();
  }, [rerenderUid, messageWrapperRef])

  const imageRows = imageGroups.map((group, index) => {
    //считаем W и H для картинок, чтобы они красиво умещались в сетку с динамическим разделителем

    let h = Math.min(...group.map(x => x.height), (imagesRowWidth - (group.length - 1) * props.gap) / (group.map(x => x.aspectRatio).reduce((acc, next) => acc + next)));

    return (<ImageGridRowStyled key={index} $gap={props.gap}>
      {group.map((x, gIndex) => {
        const borderRadius: ImageWrapperStyledBorderRadius = {
          topLeft: index === 0 && gIndex === 0,
          topRight: index === 0 && (gIndex === 1 || group.length === 1),
          bottomLeft: !props.text && (index === imageGroups.length - 1 && gIndex === 0),
          bottomRight: !props.text && (index === imageGroups.length - 1 && (gIndex === 1 || group.length === 1))
        }
        const onClick = (src: string) => {
          imageExpandContext.showImageExpand(src, props.imageMetas);
        }
        return (
          <ImageWrapperStyled key={gIndex} $gap={props.gap} $w={h * group[gIndex].aspectRatio} $h={h}
            $borderRadius={borderRadius} onClick={() => onClick(x.url)}>
            <ImageBase src={x.url} sizes={{ w: 0, h: 0 }} />
          </ImageWrapperStyled>)
      })}
    </ImageGridRowStyled>
    )
  })

  return (
    <MessageImageGalleryStyled ref={gallaryRef} className="gallery">
      <ImageGalleryFitContentWrapperStyled ref={gallaryWrapperRef} $gap={props.gap} className="gallery-wrapper">
        {imageRows}
      </ImageGalleryFitContentWrapperStyled>
      {props.text && <MessageTextStyled widthParam={imagesRowWidth} side={props.side} last={props.last} dangerouslySetInnerHTML={{__html: props.text}}>
        </MessageTextStyled>}
    </MessageImageGalleryStyled>
  )
}

export default ImageGallary

const MessageImageGalleryStyled = styled.div`
display: flex;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  flex-direction: column;
`

const MessageTextStyled = styled.span<MessageTextStyledProps>`
background-color: var(--background-color);
padding: 8px 16px 12px 16px;
width: ${props => props.widthParam ? `${props.widthParam}px` : 'fit-content'};
border-bottom-left-radius: ${props => props.last && props.side === MessageSide.Bot ? "0" : "8px"};
border-bottom-right-radius: ${props => props.last && props.side === MessageSide.User ? "0" : "8px"};
box-sizing: border-box;

>a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
`

interface MessageTextStyledProps {
  widthParam: number | undefined,
  side: MessageSide,
  last: boolean
}

//TODO сделать лучше через styled.div.attr, чтобы не генирировать кучу классов
//проблема в том, что почему-то немного неправильно расчитывается высота одной из картинок
const ImageWrapperStyled = styled.div<ImageWrapperStyledProp>`
display: flex;
position: relative;
border-top-left-radius: ${props => props.$borderRadius.topLeft ? "10px" : ""};
border-top-right-radius: ${props => props.$borderRadius.topRight ? "10px" : ""};
border-bottom-left-radius: ${props => props.$borderRadius.bottomLeft ? "10px" : ""};
border-bottom-right-radius: ${props => props.$borderRadius.bottomRight ? "10px" : ""};
overflow: hidden;
justify-content: center;
align-items: center;
align-content: center;
// transition: transform 0.1s ease-in-out;
width: ${props => props.$w}px;
height: ${props => props.$h}px;
cursor: pointer;

// &:hover{
//   transform: scale(1.05);  
// }
`

interface ImageWrapperStyledProp {
  $gap: number,
  $w: number,
  $h: number,
  $borderRadius: ImageWrapperStyledBorderRadius,
}

interface ImageWrapperStyledBorderRadius {
  topLeft: boolean,
  topRight: boolean,
  bottomLeft: boolean,
  bottomRight: boolean
}

const ImageGalleryFitContentWrapperStyled = styled.div<ImageGalleryFitContentWrapperStyledProps>`
display: flex;
background-color: var(--background-color);
width: fit-content;
flex-direction: column;
gap:  ${props => props.$gap + "px"};
border-radius: 8px;
`

interface ImageGalleryFitContentWrapperStyledProps {
  $gap: number;
}

const ImageGridRowStyled = styled.div<ImageGridRowStyledProp>`
display: flex;
gap:  ${props => props.$gap + "px"};
`
interface ImageGridRowStyledProp {
  $gap: number,
}
