import { useCallback, useRef, useState, useContext } from "react";
import { styled } from "styled-components";
import { VideoDispatchContext, VideoStateContext } from "./VideoContextProvider";

interface ProgressBarProps {
    play: () => void;
    pause: () => void;
    canSeek: boolean;
}

const ProgressBar = (props: ProgressBarProps) => {

    const state = useContext(VideoStateContext);
    const dispatch = useContext(VideoDispatchContext);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const dragRef = useRef<boolean>(false);

    const isMouseEvent = (e: MouseEvent | TouchEvent): e is MouseEvent => {
        return "clientX" in e;
    }

    const progressbarClickHandler = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.canSeek)
            return;

        const rect = wrapperRef.current!.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const progress = offsetX / rect.width * 100;
        dispatch && dispatch({ type: "setPlayProgress", payload: progress });
    }, [wrapperRef, props])

    const trackDragEndHandler = useCallback((e: MouseEvent | TouchEvent) => {
        dragRef.current = false;
        const rect = wrapperRef.current!.getBoundingClientRect();
        let offsetX: number = 0;
        if (isMouseEvent(e))
            offsetX = e.clientX - rect.left;
        else
            offsetX = e.touches[0].clientX - rect.left;

        const progress = Math.min(Math.max(offsetX / rect.width * 100, 0), 100);
        dispatch && dispatch({ type: "setPlayProgress", payload: progress });

        setTimeout(() => {
            props.play();
        }, 300);

        if (isMouseEvent(e)) {
            document.removeEventListener('mousemove', trackDragHandler);
            document.removeEventListener('mouseup', trackDragEndHandler);
        } else {
            document.removeEventListener('touchmove', trackDragHandler);
            document.removeEventListener('touchend', trackDragEndHandler);
        }
    }, [dragRef, props])

    const trackDragHandler = useCallback((e: MouseEvent | TouchEvent) => {
        if (!dragRef.current)
            return;

        const rect = wrapperRef.current!.getBoundingClientRect();
        let offsetX: number = 0;
        if (isMouseEvent(e))
            offsetX = e.clientX - rect.left;
        else
            offsetX = e.touches[0].clientX - rect.left;
        const progress = Math.min(Math.max(offsetX / rect.width * 100, 0), 100);
        dispatch && dispatch({ type: "setPlayProgress", payload: progress });

        e.preventDefault();
    }, [dragRef, props])

    const trackDragStartHandler = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.PointerEvent<HTMLDivElement>) => {
        if (!props.canSeek)
            return;

        props.pause();
        dragRef.current = true;

        if (isMouseEvent(e.nativeEvent)) {
            document.addEventListener('mousemove', trackDragHandler);
            document.addEventListener('mouseup', trackDragEndHandler);
        } else {
            document.addEventListener('touchmove', trackDragHandler);
            document.addEventListener('touchend', trackDragEndHandler);
        }
    }, [dragRef, props, trackDragHandler, trackDragEndHandler])

    return (
        <ProgressWrapperStyled ref={wrapperRef} onMouseDown={trackDragStartHandler} onPointerDown={trackDragStartHandler} onClick={progressbarClickHandler}>
            {props.canSeek && <ProgressBarPinStyled id="pin" $progress={state?.playProgress || 0} />}
            <ProgressBarStyled >
                <ProgressBarShadowStyled $progress={state?.playProgress || 0} />
                <ProgressBarPreloadedShadowStyled $progress={state?.preloadProgress || 0} />
            </ProgressBarStyled>
        </ProgressWrapperStyled>
    )
}

export default ProgressBar;

interface ProgressBarPinStyledProps {
    $progress: number
}

const ProgressBarPinStyled = styled.div.attrs<ProgressBarPinStyledProps>(props => ({
    style: {
        left: `${props.$progress}%`,
    }
}))`
position: absolute;
background-color: white;
width: 12px;
height: 12px;
border-radius: 50%;
transform: translateX(-50%);
z-index: 5;

&:hover {
    transform: translateX(-50%) scale(1.2);
}
`

const ProgressWrapperStyled = styled.div`
display: flex;
flex-grow: 1;
align-items: center;
flex-basis: 75%;
position: relative;
pointer-events: all;
`

const ProgressBarStyled = styled.div`
display: flex;
position: relative;
height: 7px;
width: 100%;
border-radius: 4px;
overflow: hidden;
border: 1px solid #a9a9a959;

`

interface ProgressBarStyledProps {
    $progress: number
}

const ProgressBarShadowStyled = styled.div<ProgressBarStyledProps>`
position: absolute;
width: ${props => props.$progress}%;
height: 100%;
background: #FFF;
z-index: 2;
`

const ProgressBarPreloadedShadowStyled = styled.div<ProgressBarStyledProps>`
position: absolute;
width: ${props => props.$progress}%;
height: 100%;
z-index: 1;
background: rgba(255,255,255,.3);
`