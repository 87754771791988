import ChatApp from './components/ChatRouter';
import MessagesContextProvider from './providers/MessagesProvider';
import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { init as SentryInit } from "@sentry/react"
import { BrowserTracing } from "@sentry/browser";
import ImageExpandProvider from './providers/ImageExpandProvider';
import { ENVIRONMENT, REGION, SENTRY_URL } from './services/utils';
import CookieAgreementPopup from './components/CookieAgreementPopup';
import ChatStateProvider from './providers/ChatStateProvider';
import MessageWrapperRefProvider from './providers/MessageWrapperRefProvider';
import Spinner from './components/Spinner';

SentryInit({
  dsn: `https://029daeb2b42247169312ade8a46e71db@${SENTRY_URL}//17`,
  integrations: [new BrowserTracing()],
  environment: `${ENVIRONMENT} ${REGION}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {

  const href = new URL(window.location.href);
  const params = href.searchParams;

  const [querySearch] = useState<string>(href.search);
  const [linkInvitation] = useState<string>(params.get('i') || '');
  const [interviewUid] = useState<string>(params.get('u') || '');
  const [interviewExtId] = useState<string>(params.get('e') || '');
  const [stimulusCellUid] = useState<string>(params.get('c') || '');
  const [language] = useState<string>(params.get('l') || '');

  const [cookieAgreementPopupOpen, toggleCookieAgreementPopupOpen] = useState<boolean>(localStorage.getItem('cookie-agreed') === null);

  const showCookieWall = useMemo(
    () => cookieAgreementPopupOpen && REGION !== "RU",
  [cookieAgreementPopupOpen, REGION]);

  if ("virtualKeyboard" in navigator) {
    let keyboardNavigator: KeyboardNavigatorType = navigator.virtualKeyboard as KeyboardNavigatorType;
    keyboardNavigator.overlaysContent = true;
    keyboardNavigator.ongeometrychange = () => {
      document.getElementsByTagName("html")[0].style.height = `calc(100% - ${keyboardNavigator.boundingRect.height}px)`;
    }
  }

  const appContent = useMemo( 
    () => (
    <AppStyled>
        <ChatStateProvider interviewUid={interviewUid}>
          <MessageWrapperRefProvider>
            <MessagesContextProvider messages={[]}>
              <ImageExpandProvider>
                <ChatApp
                  linkInvitation={linkInvitation}
                  interviewUid={interviewUid}
                  interviewExtId={interviewExtId}
                  querySearch={querySearch}
                  stimulusCellUid={stimulusCellUid}
                  language={language}
                />
                <ModalStyled id="modal-root" />
              </ImageExpandProvider>
            </MessagesContextProvider>
          </MessageWrapperRefProvider>
        </ChatStateProvider>
      </AppStyled>
  ), [interviewUid, linkInvitation, interviewExtId, querySearch, stimulusCellUid, language]);

  const cookieWallContent = useMemo( 
    () => (
      <>
        <Spinner /> {/* Можно спинер заменить на что-то более осмысленное, с призывом принять куки */}
        <CookieAgreementPopup
          onClose={() => { toggleCookieAgreementPopupOpen(false); }}
          language={localStorage.getItem("forced_language") || language || navigator.language.split('-')[0]}
        />
      </>
    ), [language, toggleCookieAgreementPopupOpen]);

    const content = showCookieWall ? cookieWallContent : appContent;

  return content;
}

interface KeyboardNavigatorType {
  overlaysContent: boolean,
  ongeometrychange: () => void,
  boundingRect: DOMRect
}

let AppStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
`

const ModalStyled = styled.div`
  position: relative;
  z-index: 20;

  & .modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

App.whyDidYouRender = true;

export default App;
