import { createContext, useReducer, ReactNode, Dispatch, useContext } from "react";

export function reducer(state: VideoState, action: VideoAction) {
    switch (action.type) {
        case 'setPlayProgress':
            return { ...state, playProgress: action.payload };
        case 'setPreloadProgress':
            return { ...state, preloadProgress: action.payload };
        case 'setVolumeValue':
            return { ...state, volumeValue: action.payload };
        case 'setVideoDuration':
            return { ...state, videoDuration: action.payload };
        case 'setVideoCurrentTime':
            return { ...state, videoCurrentTime: action.payload };
        case 'setStoredVolume':
            return { ...state, storedVolume: action.payload };
        default:
            throw new Error();
    }
}

  export interface VideoAction {
    type: "setPlayProgress" | "setPreloadProgress" | "setVolumeValue" | "setVideoDuration" | "setVideoCurrentTime" | "setStoredVolume",
    payload: number
  }

  //declare type VideoAction  
export interface VideoState {
    playProgress: number,
    preloadProgress: number,
    volumeValue: number,
    storedVolume: number
    videoDuration: number,
    videoCurrentTime: number,
}

function getInitialState(): VideoState {
    return {
        playProgress: 0,
        preloadProgress: 0,
        volumeValue: 100,
        storedVolume: 100,
        videoDuration: 0,
        videoCurrentTime: 0,
    }
}

export function VideoProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, getInitialState());
  
    return (
      <VideoStateContext.Provider value={state}>
        <VideoDispatchContext.Provider value={dispatch}>
          {children}
        </VideoDispatchContext.Provider>
      </VideoStateContext.Provider>
    );
  }

  export function useVideoState(): VideoState {
    const context = useContext(VideoStateContext);
    if (!context) {
      throw new Error('useVideoState must be used within a VideoProvider');
    }
    return context;
  }
  
  export function useVideoDispatch(): React.Dispatch<VideoAction> {
    const context = useContext(VideoDispatchContext);
    if (!context) {
      throw new Error('useVideoDispatch must be used within a VideoProvider');
    }
    return context;
  }


 export const VideoStateContext = createContext<VideoState | undefined>(undefined);
export const VideoDispatchContext = createContext<Dispatch<VideoAction> | undefined>(undefined);