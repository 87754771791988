import { keyframes, styled } from "styled-components"

const TypingDots = () => {
    return (
        <TypingSvgStyled width="40" className="loader">
            <TypingSvgCircleStyled className="dot" cx="10" cy="70%" r="3" style={{ fill: "gray" }} />
            <TypingSvgCircleStyled className="dot" cx="20" cy="70%" r="3" style={{ fill: "gray" }} />
            <TypingSvgCircleStyled className="dot" cx="30" cy="70%" r="3" style={{ fill: "gray" }} />
        </TypingSvgStyled>)
}

const blink = keyframes`
  50% { fill: transparent }
`

const TypingSvgStyled = styled.svg`
height: 1.5rem;
display: flex;
`

const TypingSvgCircleStyled = styled.circle`
animation: 1s ${blink} infinite;
fill: grey;
&:nth-child(2) { animation-delay: 250ms }
&:nth-child(3) { animation-delay: 500ms }
`

export default TypingDots