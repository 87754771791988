import { useCallback, useEffect, useState, MouseEvent } from 'react';
import Button, { ButtonBackgroundColor } from './Button';
import { keyframes, styled } from 'styled-components';
import { useChatClient } from '../services/utils';
import DOMPurify from 'dompurify';
import { sanitizerConfig } from '../services/utils';
import { CookieIcon } from '../assets/Icons';
import useRipple from '../hooks/ripple/useRipple';

interface CookieAgreementPopupProps {
    onClose: () => void
    language: string
}

const CookieAgreementPopup = (props: CookieAgreementPopupProps) => {

    const [closed, toggleClosed] = useState<boolean>(false);
    const [cookieAgreementText, setCookieAgreementText] = useState<string>('');

    const [rippleNodes, createRipple] = useRipple(300);

    const chatClient = useChatClient();

    useEffect(() => {
        chatClient.getCookieAgreement(props.language)
            .then((response: string) => {
                const saniztizedText = DOMPurify.sanitize(response, sanitizerConfig);
                setCookieAgreementText(saniztizedText);
            })
            .catch((error) => {
            });
    });

    const okButtonHandler = useCallback((event: MouseEvent<HTMLDivElement>) => {
        toggleClosed(true);
        localStorage.setItem('cookie-agreed', 'true');
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props]);

    return (
        <ContainerStyled>
            <BackdropStyled onClick={createRipple} />
            {cookieAgreementText &&
                <PopupStyled closed={closed} className='cookie-agreement'>
                    <TextWithIconStyled>
                        <CookieIconStyled />
                        <TextContainerStyled dangerouslySetInnerHTML={{ __html: cookieAgreementText }}>
                        </TextContainerStyled>
                    </TextWithIconStyled>
                    <ButtonStyled onClick={okButtonHandler} backgroundColor={ButtonBackgroundColor.blue}>
                        OK
                        {rippleNodes}
                    </ButtonStyled>
                </PopupStyled>}
        </ContainerStyled>
    );
};

export default CookieAgreementPopup;

const ContainerStyled = styled.div`
display: flex;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 900;

justify-content: center;
align-items: flex-end;
`

const BackdropStyled = styled.div`
display: flex;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 990;
`

interface PopupStyledProps {
    closed: boolean
}

const PopupStyled = styled.div<PopupStyledProps>`
transform: translateY(50%);
margin: auto auto;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;

overflow: hidden;

flex-direction: row;
padding: 24px;
gap: 48px;
border-radius: 8px;
position: relative;
box-shadow: 0px 0px 16px 0px #06172B14;
background: #FFFFFF;
width: 45%;

@media (max-width: 800px) {
    width: 100%;
    gap: 24px;
    padding: 16px;
    margin: 16px;
    min-width: 0px;
    flex-direction: column;
    transform: translateY(0);
}
`

const TextWithIconStyled = styled.div`
display: flex;
gap: 12px;
align-items: center;

@media (max-width: 800px) {
    flex-direction: column;
}
`

const CookieIconStyled = styled(CookieIcon)`
width: 40px;
height: 40px;
color: #7F7F8C;
`

const TextContainerStyled = styled.span`
& a:visited {
    color: #0000ff;
}

@media (max-width: 800px) {
    text-align: center;
}

`

const ButtonStyled = styled(Button)`
display: flex;
position: relative;
flex-direction: row;
padding: 8px 24px;
gap:8px;
overflow: hidden;

@media (max-width: 800px) {
    width: 100%;
}
`