import { useCallback, useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Avatar from "./Avatar"
import Dropdown, { DropdownOption } from "./Dropdown"
import { TypeAnimation } from 'react-type-animation';
import { useMediaQuery } from "../services/utils"
import Button, { ButtonBackgroundColor } from "./Button"
import ChatStateContext from "../models/ChatStateContext";
import ChatFooter from "./ChatFooter";
import TypingDots from "./TypingDots";

export interface GreetingProps {
  acceptButtonHandler: () => void,
  rejectButtonHandler: () => void,
  languageDropdownOptions: DropdownOption[],
  selectedLanguageCode: string,
  interviewUid: string,
  footerText: string
}


const Greeting = (props: GreetingProps) => {
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [isTypingSkipped, setIsTypingSkipped] = useState<boolean>(false);
  const [isAcceptButtonPressed, setIsAcceptButtonPressed] = useState<boolean>(false);
  const [isRejectButtonPressed, setIsRejectButtonPressed] = useState<boolean>(false);

  const chatStateContext = useContext(ChatStateContext);

  const TYPING_ANIMATION_SHOWN_CACHE_NAME = "TYPING_SHOWN";
  const getCurrentTypingCacheName = (interviewUid: string) => `${TYPING_ANIMATION_SHOWN_CACHE_NAME}_${interviewUid}`

  const isMobile = useMediaQuery("(max-width: 767px)");

  const rejectButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatStateContext.interviewUid) {
      const isTypingAnimationShown = localStorage.getItem(getCurrentTypingCacheName(chatStateContext.interviewUid));
      if (isTypingAnimationShown && isTypingAnimationShown === "1") {
        setIsTyping(false);
      }
    }
  }, [chatStateContext.interviewUid])

  const skipTypingClickHandler = () => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'test' && window.location.hostname !== "localhost") return;

    setIsTyping(false);
    setIsTypingSkipped(true);
    rejectButtonRef.current!.scrollIntoView();
  }

  const typingSequenceEndHandler = useCallback(() => {
    if (rejectButtonRef.current){
      setIsTyping(false);
      localStorage.setItem(getCurrentTypingCacheName(chatStateContext.interviewUid), "1");
      rejectButtonRef.current!.scrollIntoView();
    }
  }, [chatStateContext.interviewUid]);
  
  const acceptButtonClickHandler = useCallback(async () => {
    if (isAcceptButtonPressed || isRejectButtonPressed) return;
    setIsAcceptButtonPressed(true);
    await props.acceptButtonHandler();
    localStorage.removeItem(getCurrentTypingCacheName(chatStateContext.interviewUid))
  }, [chatStateContext.interviewUid, isAcceptButtonPressed, isRejectButtonPressed, props]);

  const rejectButtonClickHandler = useCallback(async () => {
    if (isAcceptButtonPressed || isRejectButtonPressed) return;
    setIsRejectButtonPressed(true);
    await props.rejectButtonHandler();
    localStorage.removeItem(getCurrentTypingCacheName(chatStateContext.interviewUid))
  }, [chatStateContext.interviewUid, isAcceptButtonPressed, isRejectButtonPressed, props]);

  return (
    <GreetingWrapperStyled className="wrapper">
      <GreetingBlockStyled className="top-block" style={{ gap: "16px"}}>
        <GreetingAvatarStyled>
          <Avatar imageUrl={chatStateContext.assistantImageUrl} width={isMobile ? "80%" : "100%"} height="auto"></Avatar>
          <GreetingAvatarNameStyled>{chatStateContext.assistantName}</GreetingAvatarNameStyled>
        </GreetingAvatarStyled>
        <GreetingColumnBlockStyled onClick={skipTypingClickHandler}>
          <GreetingMessageBubbleStyled dir="auto" $hidden={isTypingSkipped}>
            <TypeAnimation
              sequence={[
                chatStateContext.greetingModel.greetingText,
                typingSequenceEndHandler
              ]}
              speed={75}
              style={{
                textAlign: "start",
                padding: isMobile ? "12px" : "8px 16px 12px 16px",
                width: "fit-content",
              }}
              cursor={false}
              preRenderFirstString={localStorage.getItem(getCurrentTypingCacheName(chatStateContext.interviewUid)) === "1"}
            />
          </GreetingMessageBubbleStyled>
          <GreetingHiddenTextWrapperStyled $hidden={!isTypingSkipped}>
            <GreetingHiddenTextForSizeStyled dir="auto">
              {chatStateContext.greetingModel.greetingText}
            </GreetingHiddenTextForSizeStyled>
          </GreetingHiddenTextWrapperStyled>
          <GreetingBlockStyled hide={isTyping}>
            <Button 
              disabled={isAcceptButtonPressed || isRejectButtonPressed} 
              backgroundColor={ButtonBackgroundColor.lightBlue} 
              onClick={acceptButtonClickHandler}
            >
              {isAcceptButtonPressed ? (<TypingDots />) : chatStateContext.greetingModel.acceptButtonText}
            </Button>
            <Button 
              disabled={isAcceptButtonPressed || isRejectButtonPressed} 
              backgroundColor={ButtonBackgroundColor.lightBlue} 
              ref={rejectButtonRef} 
              onClick={rejectButtonClickHandler}
              >
                {isRejectButtonPressed ? (<TypingDots />) : chatStateContext.greetingModel.rejectButtonText}
              </Button>
          </GreetingBlockStyled>
          <FooterStyled>
        <ChatFooter text={props.footerText}/>
          <Dropdown 
            disabled={isAcceptButtonPressed || isRejectButtonPressed} 
            options={props.languageDropdownOptions} 
            selectedOptionCode={props.selectedLanguageCode} 
          />
        </FooterStyled>
        </GreetingColumnBlockStyled>
      </GreetingBlockStyled>
    </GreetingWrapperStyled>
  )
}

export default Greeting

const FooterStyled = styled.footer`
  display: flex;
  flex: 1;
  align-items: center;
`

const GreetingHiddenTextWrapperStyled = styled.div<GreetingHiddenTextWrapperStyledProps>`
  border-radius: 8px;
  width: fit-content;
  display: flex;
  ${props => props.$hidden ? "visibility: hidden;" : ""}
`

interface GreetingHiddenTextWrapperStyledProps {
  $hidden: boolean
}

const GreetingHiddenTextForSizeStyled = styled.span`
  text-align: start;
  white-space: pre-wrap;
  padding: 8px 16px 12px 16px;
  border-radius: 8px;
  width: fit-content;

  @media (max-width: 767px) {
    padding: 12px;
  }
`

const GreetingMessageBubbleStyled = styled.div<GreetingMessageBubbleStyledProps>`
  display: flex;
  justify-content: end;
  flex-direction: column;
  white-space: pre-wrap;
  --background-color: #F2F2F7;
  --color: #2C2C2E;
  position: absolute;
  text-align: start;
  width: 100%;
  ${props => props.$hidden ? "visibility: hidden;" : ""}
`

interface GreetingMessageBubbleStyledProps {
  $hidden: boolean
}

let GreetingAvatarNameStyled = styled.span`
  font-size: 13px;
  line-height: 20px;
  color: #2C2C2E;
  opacity: 0.4;
`

let GreetingWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  padding-bottom: 2rem;
  margin: auto;
  scroll-behavior: smooth;

  @media (max-width: 767px) {
    overflow: auto;
  }
`

interface GreetingBlockStyledProps {
  hide?: boolean
}

let GreetingBlockStyled = styled.div<GreetingBlockStyledProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  ${props => props.hide ? "visibility: hidden;" : ""}

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

let GreetingColumnBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 66%;
  gap: 20px;
  position: relative;

  @media (max-width: 767px) {
    gap: 12px;
  }
`

let GreetingAvatarStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
  justify-content: start;
  align-items: center;

  @media (max-width: 767px) {
    width: 66%;
    align-self: center;
    align-items: center;
    margin-top: 12px;
    max-width: 300px;
  }
`