import { LegacyRef, useCallback, useEffect, useRef, useState, useReducer, createContext, useContext, useMemo } from "react"
import styled from 'styled-components';
import { IoPlayIcon, IoPauseIcon, IoScanIcon } from "../../assets/Icons";
import screenfull from 'screenfull';
import Modal from "../Modal/Modal";
import { iOS, useTouchScreen } from "../../services/utils";
import PlayerTimer from "./PlayerTimer";
import ProgressBar from "./PlayerProgressBar";
import ControlButtonStyled from "./PlayerControlButton";
import PlayerAudioControl from "./PlayerAudioControl";
import { useVideoDispatch } from "./VideoContextProvider";
import BackdropStyled, { BackdropColor } from "../Modal/BackdropStyled";

// TODO: Здесь очень много варнингов про мемоизацию, монтирований событий без размонтирования и просто грязный код

interface VideoProps {
    src: string,
    posterSrc?: string,
    aspectRatio: number,
    widthCallback?: (width: number) => void
}

const _videoMaxHeightMultiplier = 0.75;

const Video = (props: VideoProps) => {

    const videoRef: LegacyRef<HTMLVideoElement> = useRef(null);
    const videoContainerRef: LegacyRef<HTMLDivElement> = useRef(null);
    let innactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const mobile = useTouchScreen();

    const [isControlsShown, setIsControlsShown] = useState<boolean>(true);
    const [isVolumeControlShown, setIsVolumeControlShown] = useState<boolean>(mobile);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(true);
    const [isPlayStarted, setIsPlayStarted] = useState<boolean>(false);

    const [canSeek, setCanSeek] = useState<boolean>(false);

    const dispatch = useVideoDispatch();

    const [wrapperWidth, setWrapperWidth] = useState<number>(0);

    useEffect(() => {
        const divWidth = videoContainerRef.current?.getBoundingClientRect().width || 0;
        setWrapperWidth(divWidth);
        props.widthCallback && props.widthCallback(divWidth);
    }, [])

    const updatePlayProgress = useCallback(() => {
        if (videoRef.current) {
            dispatch && dispatch({ type: "setPlayProgress", payload: videoRef.current.currentTime / videoRef.current.duration * 100 });
            if (!canSeek && videoRef.current.currentTime === videoRef.current.duration)
                setCanSeek(true);
        }
    }, [videoRef, canSeek])

    const updatePreloadProgress = useCallback(() => {
        if (videoRef.current && videoRef.current.buffered.length > 0) {
            dispatch && dispatch({ type: "setPreloadProgress", payload: videoRef.current.buffered.end(0) / videoRef.current.duration * 100 });
        }
    }, [videoRef])

    const toggleFullscreen = async () => {
        if (!videoContainerRef.current)
            return;

        let toScroll = screenfull.isFullscreen;

        setIsFullscreen(!isFullscreen);

        if (!iOS())
            await screenfull.toggle(videoContainerRef.current, { navigationUI: 'hide' });

        if (toScroll) {
            // хак, чтобы вернуться к позиции скрола после выхода из фуллскрина (почему-то сбрасывается)
            setTimeout(() => {
                videoContainerRef.current!.scrollIntoView();
            }, 200);
        }
    }

    const togglePlay = useCallback(() => {
        if (videoRef.current?.paused)
            play();
        else
            pause();
    }, [videoRef])

    const play = useCallback(() => {
        console.log(videoRef.current);
        if (videoRef.current?.paused) {
            videoRef.current?.play().then(() => {
                setIsPaused(false);
            });
        }
    }, [videoRef])

    const pause = useCallback(() => {
        if (!videoRef.current?.paused) {
            setIsPaused(true);
            videoRef.current?.pause();
        }
    }, [videoRef])

    //show controls and hide after 3s of inactivity
    const showControls = useCallback(() => {
        setIsControlsShown(true);

        if (innactivityTimeoutRef.current)
            clearTimeout(innactivityTimeoutRef.current);

        let timeoutId = setTimeout(() => {
            if (videoRef.current?.paused)
                return;

            innactivityTimeoutRef.current = null;
            setIsControlsShown(false);
        }, 3000);

        innactivityTimeoutRef.current = timeoutId;
    }, [innactivityTimeoutRef, videoRef])

    const videoContainerClickHandler = useCallback(() => {
        if (mobile && !isPaused) {
            setIsControlsShown(!isControlsShown);
        }
        else {
            showControls();
            togglePlay();
        }
    }, [isPaused, isControlsShown, mobile, showControls, togglePlay])

    const audioVolumeContainerMouseEnterHandler = useCallback(() => {
        if (screenfull.isFullscreen)
            return;

        setIsVolumeControlShown(true);
    }, [setIsVolumeControlShown])

    const audioVolumeContainerMouseLeaveHandler = useCallback(() => {
        if (screenfull.isFullscreen)
            return;

        setIsVolumeControlShown(false);
    }, [setIsVolumeControlShown])

    const volumeChangeHandler = useCallback((value: number) => {
        if (!videoRef.current)
            return;

        videoRef.current.volume = value;

    }, [videoRef])

    const firstInteractionHandler = useCallback(() => {
        setIsPlayStarted(true);
        togglePlay();
    }, [togglePlay])

    const onLoadedmetadataHandler = useCallback(() => {
        dispatch && dispatch({ type: "setVideoDuration", payload: videoRef.current?.duration || 0 });
    }, [])

    const onTimeUpdateHandler = useCallback(() => {
        dispatch && dispatch({ type: "setVideoCurrentTime", payload: videoRef.current?.currentTime || 0 });
    }, [])

    const onError = (e: any) => {
        console.warn('error', e)
    }

    const onResize = () => {
        const divWidth = videoContainerRef.current?.getBoundingClientRect().width || 0;
        setWrapperWidth(divWidth);
        props.widthCallback && props.widthCallback(divWidth);
    }

    let controls = <></>;
    if (wrapperWidth >= 500) {
        controls = (
            <ControlsRowStyled>
                    <PlayButtonStyled onClick={togglePlay}>
                        {isPaused ? <IoPlayIcon /> : <IoPauseIcon />}
                    </PlayButtonStyled>
                    <PlayerTimer />
                    <ProgressBar canSeek={canSeek} pause={pause} play={play} />
                    {!mobile && <PlayerAudioControl isFullscreen={isFullscreen} isVolumeControlShown={isVolumeControlShown}
                        setVolume={volumeChangeHandler}
                        audioVolumeContainerMouseEnterHandler={audioVolumeContainerMouseEnterHandler}
                        audioVolumeContainerMouseLeaveHandler={audioVolumeContainerMouseLeaveHandler} />}
                    <FullscreenControlStyled onClick={toggleFullscreen}>
                        <IoScanIcon />
                    </FullscreenControlStyled>
            </ControlsRowStyled>)
    }
    else {
        controls = (<>
            <ControlsRowStyled>
                    <PlayButtonStyled onClick={togglePlay}>
                        {isPaused ? <IoPlayIcon /> : <IoPauseIcon />}
                    </PlayButtonStyled>
                    <PlayerTimer />
                    <ControlsPlaceholderStyled />
                    {!mobile && <PlayerAudioControl isFullscreen={isFullscreen} isVolumeControlShown={isVolumeControlShown}
                        setVolume={volumeChangeHandler}
                        audioVolumeContainerMouseEnterHandler={audioVolumeContainerMouseEnterHandler}
                        audioVolumeContainerMouseLeaveHandler={audioVolumeContainerMouseLeaveHandler} />}
                    <FullscreenControlStyled onClick={toggleFullscreen}>
                        <IoScanIcon />
                    </FullscreenControlStyled>
            </ControlsRowStyled>
            <ControlsRowStyled>
                <ProgressBar canSeek={canSeek} pause={pause} play={play} />
            </ControlsRowStyled>
        </>)
    }

    const videoPlayerContent = (
        <>
            <VideoStyled ref={videoRef} onTimeUpdate={updatePlayProgress} onProgress={updatePreloadProgress}
                onClick={videoContainerClickHandler} poster={props.posterSrc} $aspectRatio={props.aspectRatio}
                onLoadedMetadata={onLoadedmetadataHandler} onTimeUpdateCapture={onTimeUpdateHandler}
                onError={onError} onStalled={onError}
                playsInline={true} webkit-playsinline={true} preload="auto" autoPlay={false}
            >
                <source src={props.src} type="video/mp4" />
            </VideoStyled>
            {!isPlayStarted && <BigPlayButtonContainerStyled onClick={firstInteractionHandler}>
                <IoPlayIcon />
            </BigPlayButtonContainerStyled>}
            {isPlayStarted && <VideoControlsWrapperStyled $isControlsShown={isControlsShown}>
                {controls}
            </VideoControlsWrapperStyled>}
        </>
    )

    let content = (<></>)
    if (iOS() && isFullscreen && mobile) {
        content = (<Modal>
            <BackdropStyled color={BackdropColor.Grey} />
            <VideoWrapperStyled>
                {videoPlayerContent}
            </VideoWrapperStyled>
        </Modal>)
    }
    else {
        content = videoPlayerContent;
    }

    const maxHeight = props.aspectRatio < 1 && props.aspectRatio > 0 ? window.innerHeight * _videoMaxHeightMultiplier : undefined;

    return (
        <VideoContainerStyled ref={videoContainerRef} onTouchMove={showControls} onMouseMove={showControls}
            $maxHeight={maxHeight} $aspectRatio={props.aspectRatio} onResize={onResize}
        >
            <PlayerWrapperStyled>
                {content}
            </PlayerWrapperStyled>
        </VideoContainerStyled>
    )
}

export default Video

const VideoWrapperStyled = styled.div`
width: 100%;
display: flex;
height: fit-content;
align-self: center;
position: relative;
overflow: hidden;
`

const PlayerWrapperStyled = styled.div`
display: flex;
width: 100%;
height: 100%;
z-index: 11;
align-self: center;
position: relative;
`

const ControlsPlaceholderStyled = styled.div`
display: flex;
flex-grow: 1;
`

const ControlsRowStyled = styled.div`
display: flex;
width: 100%;
box-sizing: border-box;

&>*{
    margin-inline: 0.5rem;
}
`

const BigPlayButtonContainerStyled = styled.div`
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index:3;

&>.fai-chat-icon {
    width: 30%;
    height: 30%;
    opacity: 0.6;
    transition: transform 0.1s;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: translateY(2px); /* Move the button down slightly */
    }
}
`

interface VideoContainerStyledProps {
    $maxHeight?: number,
    $aspectRatio: number
}

const VideoContainerStyled = styled.div<VideoContainerStyledProps>`
display: flex;
position: relative;
overflow: hidden;
//width: 100%;
width: ${props => props.$maxHeight ? `calc(${props.$maxHeight}px * ${props.$aspectRatio == 0 ? props.$aspectRatio : _videoMaxHeightMultiplier})` : '100%'};
max-height: ${props => props.$maxHeight === 0 ? '100%' : `${props.$maxHeight}px`};
`

interface VideoStyledProps {
    $aspectRatio: number
}

const VideoStyled = styled.video<VideoStyledProps>`
width: 100%;
height: auto;
object-fit: contain;
aspect-ratio: ${props => props.$aspectRatio};
z-index:2;
`

interface VideoControlsWrapperStyledProps {
    $isControlsShown: boolean
}

const VideoControlsWrapperStyled = styled.div<VideoControlsWrapperStyledProps>`
display: flex;
position: absolute;
flex-direction: column;
width: 100%;
padding: 0.4rem;
gap: 0.4rem;
box-sizing: border-box;
z-index: 2;
bottom: 0;
margin-bottom: 2%;
backdrop-filter: blur(20px) saturate(1.5) brightness(1.2);
background: rgba(0,0,0,.6);
border-radius: 5px;
width: 90%;
transform: translateX(5%);
${props => props.$isControlsShown ? '' : 'transform: translate(5%, 100%);'}
${props => props.$isControlsShown ? '' : 'margin-bottom: 0;'}
transition: 0.3s ease-in-out;

.fai-chat-icon {
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
    margin: auto;
    color: white;

    &:hover {
        transform: scale(1.1);
    }
}   
`

const PlayButtonStyled = styled(ControlButtonStyled)`
height: 100%;
max-width: 15%;
cursor: pointer; 
`

const FullscreenControlStyled = styled(ControlButtonStyled)`
`