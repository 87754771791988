import { forwardRef, MouseEvent, ReactNode, useCallback } from "react";
import { styled } from "styled-components";

enum ButtonColor { 
    white, 
    black,
    lightBlue
}
export enum ButtonBackgroundColor { 
    lightBlue, 
    blue,
    white, 
    red
}

interface ButtonProps {
    backgroundColor: ButtonBackgroundColor;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    children: ReactNode;
    disabled?: boolean;
}

const Button = forwardRef<HTMLDivElement, ButtonProps >((props, ref) => {

    const getBackgroundColor = useCallback(() => {
        switch (props.backgroundColor) {
            case ButtonBackgroundColor.lightBlue:
                return "221, 236, 251";
            case ButtonBackgroundColor.blue:
                return "0, 122, 255";
            case ButtonBackgroundColor.red:
                return "255, 43, 43";
            case ButtonBackgroundColor.white:
                return "255, 255, 255";
        
            default:
                throw Error("Not implemented")
        }
    }, [props.backgroundColor]);

    const getTextColor = useCallback(() => {
        let textColor: ButtonColor = ButtonColor.black;
        switch (props.backgroundColor) {
            case ButtonBackgroundColor.lightBlue:
                textColor = ButtonColor.lightBlue;
                break;
            case ButtonBackgroundColor.blue:
                textColor = ButtonColor.white;
                break;
            case ButtonBackgroundColor.red:
                textColor = ButtonColor.white;
                break;
            case ButtonBackgroundColor.white:
                textColor = ButtonColor.black;
                break;
        
            default:
                throw Error("Not implemented");
        }

        switch (textColor) {
            case ButtonColor.black:
                return "#1F1F22";
            case ButtonColor.lightBlue:
                return "#0048CD";
            case ButtonColor.white:
                return "#FFFFFF"
            default:
                throw Error("Not implemented");
        }
    }, [props.backgroundColor]);

    const getBorder = useCallback(() => {
        switch (props.backgroundColor) {
            case ButtonBackgroundColor.white:
                return "1px solid #E5E5E5";
        
            default:
                return undefined;
        }
    }, [props.backgroundColor]);

    const clickHandler = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (props.disabled) return;
        props.onClick(event);
    }, [props]);

    return (
        <ButtonStyled {...props} disabled={!!props.disabled} ref={ref} onClick={clickHandler} backgroundColor={getBackgroundColor()} 
                      color={getTextColor()} border={getBorder()}>
            {props.children}
        </ButtonStyled>
    )
})

export default Button;

interface ButtonStyledProps {
    color: string,
    backgroundColor: string,
    border?: string;
    disabled: boolean;
}

//TODO сделать цвета через тему
//TODO решить что-то насчёт цветов кнопок: желательно чтобы все были с альфой, чтобы было поле для добавления эффектов ховера и active
let ButtonStyled = styled.div<ButtonStyledProps>`
flex: 1;
display: flex;
justify-content: center;
align-items: center;
min-height: 56px;
text-align: center;

--background-rgb: ${props => props.backgroundColor};
background: rgba(var(--background-rgb), 0.8);
${props => props.border ? `border: ${props.border};` : ""}
border-radius: 8px;
padding: 8px 16px 12px;
box-sizing: border-box;
color: ${props => props.color};

cursor: ${props => props.disabled ? 'auto' : 'pointer'};
user-select: none;

&:hover {
  background: ${props => props.disabled ? 'rgba(var(--background-rgb), 0.8)' : 'rgba(var(--background-rgb), 0.9)'};
}

&:active {
  background: ${props => props.disabled ? 'rgba(var(--background-rgb), 0.8)' : 'rgba(var(--background-rgb), 1)'};
}

opacity: ${props => props.disabled ? '0.5' : '1'};
`;