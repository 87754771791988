import { useState } from 'react';
import styled from 'styled-components';

type StarRatingProps = {
    questionText: string;
    onSubmit: (score: number) => Promise<void>;
    isScoreSet:boolean;
    style?: React.CSSProperties;
}

const StarRating = (props: StarRatingProps) => {
    const [hoverScore, setHoverScore] = useState<number | null>(null);
    const [isRatingButtonPressed, setIsRatingButtonPressed] = useState<boolean>(false);

    const handleSubmit = async (score:number | null) => {
        if(score == null)
            return;

        if (isRatingButtonPressed)
            return;

        setIsRatingButtonPressed(true);

        props.onSubmit(score) 
    }

    const handleStarMouseOver = (index: number) => {
        setHoverScore(index);
    };

    const handleStarMouseOut = () => {
        setHoverScore(null);
    };

    if (props.isScoreSet) {
        return (
            <SubmittedScoreStyled style={props.style}>
                <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 33.5C27.0604 33.5 34 26.5604 34 18C34 9.43959 27.0604 2.5 18.5 2.5C9.93959 2.5 3 9.43959 3 18C3 26.5604 9.93959 33.5 18.5 33.5ZM18.5 36C28.4411 36 36.5 27.9411 36.5 18C36.5 8.05887 28.4411 0 18.5 0C8.55887 0 0.5 8.05887 0.5 18C0.5 27.9411 8.55887 36 18.5 36Z" fill="#33C172" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4045 13.1372C26.881 13.6368 26.8623 14.428 26.3628 14.9045L17.9757 22.9045C17.4927 23.3652 16.7331 23.3652 16.2501 22.9045L11.6372 18.5045C11.1377 18.028 11.119 17.2368 11.5955 16.7372C12.072 16.2377 12.8632 16.219 13.3628 16.6955L17.1129 20.2725L24.6372 13.0955C25.1368 12.619 25.928 12.6377 26.4045 13.1372Z" fill="#33C172" />
                </svg>
            </SubmittedScoreStyled>
        )
    }

    return (
        <ScoreRequestStyled style={props.style}>
            <QuestionTextStyled>{props.questionText}</QuestionTextStyled>
            <RatingContainerStyled>
            <StarsContainer>
            {Array.from({ length: 5 }, (_, index) => (
                <StarStyled 
                    key={index}
                    onClick={() => handleSubmit(index + 1)}
                    onMouseOver={() => handleStarMouseOver(index + 1)}
                    onMouseOut={() => handleStarMouseOut()}
                >
                    <svg viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6093 0.74536C15.9804 0.0181094 17.0196 0.0181087 17.3907 0.74536L21.6646 9.1201C21.8099 9.40482 22.0826 9.60292 22.3983 9.65313L31.6838 11.1299C32.4902 11.2582 32.8113 12.2465 32.2343 12.8242L25.5902 19.4768C25.3643 19.703 25.2602 20.0235 25.31 20.3393L26.7748 29.6267C26.9021 30.4332 26.0614 31.044 25.3336 30.6738L16.9534 26.4107C16.6685 26.2657 16.3315 26.2657 16.0466 26.4107L7.66637 30.6738C6.93864 31.044 6.09795 30.4332 6.22516 29.6267L7.69004 20.3393C7.73984 20.0235 7.6357 19.703 7.40981 19.4768L0.765673 12.8242C0.188705 12.2465 0.50982 11.2582 1.31616 11.1299L10.6017 9.65313C10.9174 9.60292 11.1901 9.40482 11.3354 9.1201L15.6093 0.74536Z"
                        fill={hoverScore !== null && index < hoverScore ? "#FFCF21" : "#E5E5E5"} />
                    </svg>
                </StarStyled>
            ))}
        </StarsContainer>
            </RatingContainerStyled>
        </ScoreRequestStyled>
    );
};

const ScoreRequestStyled = styled.div`
    padding: 16px 24px 24px 24px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    @media (max-width: 800px) {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const QuestionTextStyled = styled.span`
    font-weight: 600;
`;

const RatingContainerStyled = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0px 16px 0px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

const SubmittedScoreStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 44px 24px 52px 24px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
`;

const StarsContainer = styled.div`
    display: flex;
`;

const StarStyled = styled.div`
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;
    svg {
        width: 36px;
        height: 36px;
    }
`;

export default StarRating;
